import { Descendant } from 'slate';

import { IViewportRefs } from './usePrompterViewportRefs';
import IPrompterPosition from '../../models/segments/IPrompterPosition';

import useGetPrompterPositionFunction from './useGetPrompterPositionFunction';
import usePrompterScrollToPositionFunction, { ScrollToArgs } from './usePrompterScrollToPositionFunction';
import usePrompterScrollByDeltaFunction, { ScrollByArgs } from './usePrompterScrollByDeltaFunction';
import usePrompterScrollToSegmentFunction from './usePrompterScrollToSegmentFunction';
import useSegmentNavigationFunctions, { PushLastScrollTargetArgs, ScrollPositionQueueEntry } from './useSegmentNavigationFunctions';
import useSequentialTaskQueue from './useSequentialTaskQueue';

export interface IViewportFuncs {
  getPrompterPosition: () => IPrompterPosition | undefined,
  scrollToPositionPromise: (args?: ScrollToArgs | undefined) => Promise<void>,
  scrollToPosition: (args?: ScrollToArgs | undefined) => void,
  scrollByDelta: (args?: ScrollByArgs | undefined) => Promise<void> | undefined,
  scrollToSegment: (targetSegment: Descendant, targetPosition?: string | undefined) => Promise<void>,
  getSegmentIndex: (targetSegment: Descendant) => number,
  getSegmentIndexRelativeToActive: (offsetFromCurrent: number) => number,
  pushLastScrollTarget: (args: PushLastScrollTargetArgs) => ScrollPositionQueueEntry,
  shiftLastScrollTarget: () => PushLastScrollTargetArgs | undefined,
  clearLastTargetSegmentIndex: () => void,
  queueSequentialTask: (promiseInstance: () => Promise<void>) => Promise<void>,
}

function usePrompterViewportFuncs(
  viewportRefs: IViewportRefs,
): IViewportFuncs
{
  const getPrompterPosition = useGetPrompterPositionFunction(viewportRefs);
  const {
    scrollToPositionPromise,
    scrollToPosition,
  } = usePrompterScrollToPositionFunction(viewportRefs);

  const {
    getSegmentIndex,
    getSegmentIndexRelativeToActive,
    pushLastScrollTarget,
    shiftLastScrollTarget,
    clearLastTargetSegmentIndex
  } = useSegmentNavigationFunctions();

  const scrollByDelta = usePrompterScrollByDeltaFunction(viewportRefs, scrollToPositionPromise, pushLastScrollTarget, shiftLastScrollTarget);
  const scrollToSegment = usePrompterScrollToSegmentFunction(getPrompterPosition, scrollToPositionPromise, pushLastScrollTarget, shiftLastScrollTarget);

  const queueSequentialTask = useSequentialTaskQueue();

  return {
    getPrompterPosition,
    scrollToPositionPromise,
    scrollToPosition,
    scrollByDelta,
    scrollToSegment,
    getSegmentIndex,
    getSegmentIndexRelativeToActive,
    pushLastScrollTarget,
    shiftLastScrollTarget,
    clearLastTargetSegmentIndex,
    queueSequentialTask,
  };
}

export default usePrompterViewportFuncs;