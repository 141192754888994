import { Descendant, Text } from 'slate';
import colorString from 'color-string';
import { HSLToRGB, findClosestHighlightColor } from '../../ColorUtils';

import { jsx } from 'slate-hyperscript';
import { IScriptFileParser } from '../IScriptFileParser';
import { astNode } from '../parsing/ParsingContext';
import {
  ElementTypes,
  PrompterElement,
  PrompterSegment,
  PrompterTextMarkKeys,
  PrompterTextMarks,
  PrompterTextMarkValue,
} from '../../../models/EditorTypes';
import Stack from '../../Stack';

import parseHeaderElement from './parseHeaderElement';

import { Logger, TRACE } from 'browser-bunyan';
import logger from '../../../utils/Logger';
import { PrompterTextMarksCollector } from '../PrompterTextMarksCollector';

const INLINE_TAGS = [
  'A',
  'ABBR',
  'ACRONYM',
  'B',
  'BDO',
  'BIG',
  'BR',
  'BUTTON',
  'CITE',
  'CODE',
  'DEL',
  'DFN',
  'EM',
  'I',
  'IMG',
  'INPUT',
  'KBD',
  'LABEL',
  'MAP',
  'MARK',
  'OBJECT',
  'OUTPUT',
  'Q',
  'S',
  'SAMP',
  'SCRIPT',
  'SELECT',
  'SMALL',
  'SPAN',
  'STRONG',
  'SUB',
  'SUP',
  'TEXTAREA',
  'TIME',
  'TT',
  'U',
  'VAR',
];

const IGNORED_HTML_TAGS = [
  'LINK',
  'STYLE',
  'SCRIPT',
  'IFRAME',
  'svg',
];

export interface IHTMLTagParserResuts {
  dropCurrentBlockNode?: boolean;
}
type HTML_TAG_PARSER_FN = (parser: HtmlScriptParser, node: Node, depth: number) => IHTMLTagParserResuts | void;
type ELEMENT_TAGS_LOOKUP = {
  [key: string]: HTML_TAG_PARSER_FN;
}
export const DEFAULT_HTML_ELEMENT_HANDLER: HTML_TAG_PARSER_FN = (
  parser: HtmlScriptParser,
  node: Node,
  depth: number,
) => {
  // Default handler for unknown nodes
  parser.parseChildren(node, depth + 1);
};
const HTML_ELEMENT_HANDLER: ELEMENT_TAGS_LOOKUP = {
  //
  // Handlers for H1-H6 Headers
  H1: parseHeaderElement.bind(this, 1),
  H2: parseHeaderElement.bind(this, 2),
  H3: parseHeaderElement.bind(this, 3),
  H4: parseHeaderElement.bind(this, 4),
  H5: parseHeaderElement.bind(this, 5),
  H6: parseHeaderElement.bind(this, 6),
  // A: (parser: HtmlScriptParser, node: Node) => ({ type: ElementTypes.HYPERLINK, url: (node as HTMLAnchorElement).getAttribute('href'), children: [] }),
  // BLOCKQUOTE: () => ({ type: ElementTypes.BLOCK_QUOTE, children: [] }),
  // IMG: (el: HTMLElement) => ({ type: 'image', url: el.getAttribute('src') }),
  // LI: () => ({ type: 'list-item' }),
  // OL: () => ({ type: 'numbered-list' }),
  // UL: () => ({ type: 'bulleted-list' }),
};

export interface HtmlScriptParserOptions {
  CreateSegmentsForH1?: boolean;
  CreateSegmentsForAllHeaders?: boolean;
}
const DefaultHtmlScriptParserOptions = {
  CreateSegmentsForH1: false,
  CreateSegmentsForAllHeaders: false,
};

export class HtmlScriptParser implements IScriptFileParser {

  private _resolvePromise?: (value: astNode) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _rejectPromise?: (reason?: any) => void;

  private logger: Logger;

  constructor(options?: HtmlScriptParserOptions) {
    this.config = Object.assign({}, DefaultHtmlScriptParserOptions, options);

    this.logger = logger.child({
      childName: 'HtmlScriptParser',
    });
  }
  config: HtmlScriptParserOptions;

  /**
   * As we recursively iterate down the DOM tree, we will push script nodes onto the stack when we
   * beging building a script node, and pop it off the stack when the scriptNode is finished being
   * built. This way children can be appended to the current scriptNode in progress within the
   * tree.
   */
  parsingStack: Stack<PrompterElement> = new Stack<PrompterElement>();

  /**
   * Whenever we finish a scriptNode at the root of the parsingStack, it will be added to the
   * final parsing results.
   */
  results: Descendant[] = [];

  /**
   * We will track how many sequential empty block nodes we parse to limit/cap how much empty space
   * the parser is willing to output to the user.
   */
  private _currentSequentialEmptyBlockNodeCount = 0;

  /**
   * If we encounter a serialized slate fragment attribute in the clipboard HTML, we may want to
   * prefer this representation of the content when pasting.
   */
  slateFragment: Descendant[] = [];

  /**
   *
   * @param scriptNode The scriptNode we are currently build, will be pushed on the parsingStack so
   * that we can add children to it as we continue to recurse down the HTML DOM tree.
   * @returns
   */
  private startScriptNode(scriptNode: PrompterElement): PrompterElement {
    this.parsingStack.push(scriptNode);
    return scriptNode;
  }

  /**
   * Append a scriptNode as a child of the current scriptNode at the top of the parsingStack if
   * there is one. If there is no scriptNode on the parsingStack, then we will push the first
   * result node on the stack.
   * @param scriptNode
   * @returns
   */
  private appendScriptNode(scriptNode: Descendant) {
    let parentNode = this.parsingStack.peek();
    if(!parentNode) {
      // We are at the root of the parsing stack
      return;
    }

    if(parentNode.type === ElementTypes.SCRIPT_SEGMENT && Text.isText(scriptNode)) {
      //
      // If we are appending a leaf node and are not yet inside a paragraph, then lets wrap the node
      // in a paragraph.
      //
      parentNode = this.startBlockScriptNode(ElementTypes.PARAGRAPH);
    }

    if(parentNode && parentNode.children) {
      //
      // Apply white space processing rules - trim leading white space on the first text node added
      // to a paragraph.
      //
      if(Text.isText(scriptNode)) {
        //
        // If we are adding the first child node which is a text node, then we will trim leading
        // white space (and drop the node if it is empty after trimming leading whitespace).
        //
        if(parentNode.children.length === 0 && Text.isText(scriptNode)) {
          scriptNode.text = scriptNode.text.trimStart();
        }

        //
        // Don't add empty text nodes to our editor script.
        //
        if(!scriptNode.text) {
          return;
        }
      }

      //
      // Add the current paragraph node to our results.
      //
      parentNode.children.push(scriptNode);
    }
  }

  /**
   * Finish the scriptNode at the top of the parsingStack. This will pop the current scriptNode off
   * the parsingStack and add it to the children of the parent scriptNode above it in the stack.
   *
   * Children may have been added to this scriptNode between the startScriptNode() and
   * finishScriptNode() for the current node when we recursively parsed further down the DOM tree.
   * @returns
   */
  private finishScriptNode(dropCurrentNode?: boolean) {
    const finishNode = this.parsingStack.pop();
    if(!finishNode || dropCurrentNode === true) {
      return;
    }

    //
    // If we are finishing a paragraph node, trim any trailing white spacing on the last text node.
    //
    if(finishNode.type === ElementTypes.PARAGRAPH) {
      const childrenCount = finishNode.children.length;
      const lastChild = finishNode.children[childrenCount - 1];

      if(Text.isText(lastChild)) {
        lastChild.text = lastChild.text.trimEnd();

        if(!lastChild.text) {
          finishNode.children = finishNode.children.slice(0, -1);
        }
      }
    }

    //
    // If the proposed node has no children, we don't need to add it to our script.
    //
    // One reason this can happen is that the node had its only child removed above because the
    // child was a text node that was empty or white space.
    //
    if(finishNode.children.length === 0) {
      return;
    }

    this.appendScriptNode(finishNode);
  }

  /**
   * The collection of active text marks (bold, italitc, underline, etc) that should be applied to
   * leaf text nodes.
   *
   * It is possible to specify a text mark redunduntly in HTML with nested directives such as:
   *   `<span style="font-weight: bold;"><b>Some text</b></span>`
   *
   * So we will keep a count of the directives encountered for each mark as we recursively traverse
   * the HTML DOM tree and avoid applying them redunduntly in FP.
   */
  marksCollector = new PrompterTextMarksCollector();

  /**
   * Some sources of copy-pasted script content will identify marked text using inline CSS styles
   * instead of HTML elements. We need to gather style based text marks from HTML elements.
   *
   * ex:
   *   `<span style="font-weight: bold;">bold text</span>`
   * instead of
   *   `<b>bold text</b>`
   */
  collectNodeStyles(node: Node) {
    //
    // Check for inline styles on the span
    //
    const styleMarks: PrompterTextMarks = {};
    const nodeEl = node as HTMLElement;
    const nodeStyles = nodeEl.style;
    const {
      display,
      visibility,
      fontWeight,
      fontStyle,
      textDecoration,
      backgroundColor,
    } = nodeStyles;

    //
    // Gather intrinsic styles for HTML tags that represent a text mark.
    //
    let markName: PrompterTextMarkKeys | undefined;
    let markValue: PrompterTextMarkValue = true;
    switch(node.nodeName) {
      case 'STRONG':
      case 'B':
        markName = 'bold';
        break;
      case 'EM':
      case 'I':
        markName = 'italic';
        break;
      case 'U':
        markName = 'underline';
        break;
      case 'DEL':
      case 'S':
        markName = 'strike';
        break;
      case 'MARK':
        markName = 'highlight';
        markValue = '#ffff00';
        break;
      case 'CODE':
        markName = 'code';
        break;
      case 'PRE':
        markName = 'code';
        break;
      default:
        // Unsupported text mark.
        break;
    }
    if(markName) {
      styleMarks[markName] = markValue;
    }

    //
    // Check whether this node is an inline element
    //
    let nodeIsInline = INLINE_TAGS.includes(node.nodeName);
    let isHiddenNode = false;
    if(display.indexOf('none') >= 0) {
      // This node has `display: none` css style
      isHiddenNode = true;
    }
    if(display) {
      nodeIsInline = display.toString().indexOf('inline') >= 0;
    }

    //
    // Check for CSS visibility directive
    //   visibility values can include 'hidden' but can also include suffixes like `!important`
    //   which we don't care about.
    //
    if(visibility && visibility.indexOf('hidden') >= 0) {
      // This node has `visibility: hidden` css style
      isHiddenNode = true;
    }

    //
    // Check for accesibility attribute `aria-hidden` which is used to mark HTML elements that are
    // not intended for screen readers (because they are likely there for decorative reasons).
    //
    // Any attribute value other than 'false' will be considered true.
    //
    const ariaHidden = nodeEl.getAttribute('aria-hidden');
    if(ariaHidden && ariaHidden.toLowerCase() !== 'false') {
      // This node has `aria-hidden=true` html attribute
      isHiddenNode = true;
    }

    //
    // Check if this span has a fontWeight set
    //
    if(fontWeight) {
      if(fontWeight.indexOf('bold') >= 0) {
        styleMarks['bold'] = true;
      } else if(fontWeight.indexOf('normal') >= 0) {
        // If we have a directive for `font-weight:normal` it should cancel any prior directive to
        // apply bold mark.
        styleMarks['bold'] = null;
      } else {
        // Try to parse fontWeight as number
        try {
          const fontWeightInt = parseInt(fontWeight, 10);
          if(
            !isNaN(fontWeightInt)
              && fontWeightInt >= 700
              && fontWeightInt <= 1000
          ) {
            styleMarks['bold'] = true;
          }
        } catch {
          // If we can't parse the CSS value string as a number, don't do anything.
        }
      }
    }

    //
    // Check if this span has a fontStyle set
    //
    // Note: we will treat 'italic' and 'oblique' the same, any style other than normal is equivalent
    // to italic.
    //
    if(fontStyle) {
      //
      // We do not want to handle `font-style = normal | inherit`
      //
      // `font-style = italic | oblique | oblique {Ndeg}` are all considered italic
      //
      if(fontStyle.indexOf('italic') >= 0) {
        styleMarks['italic'] = true;
      } else if(fontStyle.indexOf('oblique') >= 0) {
        styleMarks['italic'] = true;
      } else if(fontStyle.indexOf('normal') >= 0) {
        // If we have a directive for `font-weight:normal` it should cancel any prior directive to
        // apply bold mark.
        styleMarks['italic'] = null;
      }
    }

    //
    // Check if this span has a textDecoration set for underline or strike-through
    //
    if(textDecoration) {
      if(textDecoration.indexOf('underline') >= 0) {
        // text-decoration:underline
        styleMarks['underline'] = true;
      } else if(textDecoration.indexOf('line-through') >= 0) {
        // text-decoration:line-through
        styleMarks['strike'] = true;
      }
    }

    //
    // Google-docs will represent highlighted text as a span with css `backgroundColor` set.
    // But let's ignore background color set on other html elements than span.
    //
    if(backgroundColor && ['SPAN', 'MARK'].indexOf(nodeEl.nodeName) >= 0) {
      const colorInfo = colorString.get(backgroundColor);

      //
      // colorInfo will be null for an invalid background-color value.
      //
      if(colorInfo && colorInfo.model) {
        //
        // Convert HSL to RGB if required.
        //
        const rgbColor = (colorInfo.model === 'hsl')
          ? HSLToRGB(colorInfo.value)
          : colorInfo.value;

        //
        // Find the closest matching highlight color using deltaE calculation. If there is no
        // suitable match, undefined will be returned.
        //
        const closestHighlightColor = findClosestHighlightColor(rgbColor);
        if(closestHighlightColor) {
          styleMarks['highlight'] = closestHighlightColor;
        }
      }
    }

    return {
      isHiddenNode,
      isBlockNode: !nodeIsInline,
      styleMarks,
    };
  }

  /**
   * Whenever we begin parsing a block node, we need to make sure the current node on the
   * parsingStack is a block node. If it's not, then we will create a block node now and
   * push it on the parsingStack.
   */
  startBlockScriptNode(elementType: ElementTypes) {
    const currentNode = this.parsingStack.peek();

    //
    // Do we already have the correct block node on the top of the parsingStack?
    //
    if(currentNode?.type === elementType) {
      return currentNode;
    }

    //
    // Begin building a new block script node.
    //
    const newBlockNode = this.startScriptNode(
      jsx('element', {
        type: elementType
      })
    );

    return newBlockNode;
  }

  /**
   * Returns true if the provided node, including children, has no text content (not including
   * whitespace).
   * @param currentNode
   * @returns True, if empty text content
   */
  nodeIsEmpty(currentNode: Descendant): boolean {
    //
    // If this is a text node, and the content length not including any white space is 0, then it
    // is effectively empty.
    //
    if(Text.isText(currentNode)) {
      return currentNode.text.trim().length === 0;
    }

    //
    // If this is not a text node, has no children, then it is empty.
    //
    if(!currentNode.children.length) {
      return true;
    }

    //
    // Iterate over children to check if all children nodes are empty
    //
    return currentNode.children.every((child) => this.nodeIsEmpty(child));
  }

  /**
   * Whenever we finish parsing a block node, we neeed to finish any current paragraph node on the
   * parsingStack.
   */
  finishBlockScriptNode() {
    const currentNode = this.parsingStack.peek();

    //
    // If the current scriptNode (at the top of the parsingStack) is not a paragraph, don't do
    // anything.
    //
    if(currentNode?.type !== ElementTypes.PARAGRAPH) {
      return;
    }

    //
    // Keep track of how many empty blocks nodes we have parsed in a row (ie: empty paragraph or
    // empty line).
    //
    const nodeIsEmpty = this.nodeIsEmpty(currentNode);
    this._currentSequentialEmptyBlockNodeCount = nodeIsEmpty
      ? this._currentSequentialEmptyBlockNodeCount + 1
      : 0;

    //
    // If this paragraph node has no children, add an empty leaf node.
    //
    // console.log('Paragraph', currentNode);
    let dropThisNode = false;
    if(nodeIsEmpty) {
      //
      // We want to limit the number of sequential empty block nodes to 3.
      //
      if(this._currentSequentialEmptyBlockNodeCount > 3) {
        dropThisNode = true;
      }

      // console.log('Paragraph is EMPTY', currentNode);
      currentNode.children = [{ text: '' }];
    }

    //
    // If the current node being finished is not empty
    //   and, the previous sibling node is also not empty
    //   then, insert an empty line before we finalize the node at the top of the parsing stack.
    //
    if(!nodeIsEmpty) {
      //
      // Find the prior sibling node, if there is one
      //
      const preservedNode = this.parsingStack.pop();
      if(preservedNode) {
        //
        // Find the parent of the currentNode being finished.
        const parentNode = this.parsingStack.peek();
        if(parentNode && parentNode.children.length > 0) {
          //
          // The currentNode being finished has a parent which already has other children.
          const childrenCount = parentNode.children.length;
          const previousChild = parentNode.children[childrenCount - 1];
          if(!this.nodeIsEmpty(previousChild)) {
            //
            // The last child of the parent is not an empty node, therefor, insert an empty line
            parentNode.children.push({
              type: ElementTypes.PARAGRAPH,
              children: [{
                text: '',
              }],
            });
          }
        }

        //
        // Restore the currentNode at the top of the parsingStack, so we can continue our code path
        // as if nothing happened.
        this.parsingStack.push(preservedNode);
      }
    } // END if(!nodeIsEmpty)

    //
    // Finish the current paragraph script node.
    //
    this.finishScriptNode(dropThisNode);
  }

  /**
   * Will start a new script segment within which further parsed content will be collected.
   * If there was a previous segment in progress, we will finish that segment before starting a new
   * script segment.
   * @param segmentTitle Optional. Segment title parsed from a header.
   */
  startScriptSegment(segmentTitle?: string) {
    //
    // If we are currently inside an empty Script Segment, we will just replace the current script
    // segment.
    //
    const nodeStack = this.parsingStack.getItems();
    if(nodeStack.length) {
      let isEmptySegment = true;

      // console.log('| nodeStack:', JSON.parse(JSON.stringify(nodeStack)));
      for(let i = nodeStack.length - 1; i >= 0; i--) {
        const currentStackNode = nodeStack[i];
        // console.log(`|- checkNode(${i} - ${currentStackNode.type})`, JSON.parse(JSON.stringify(currentStackNode)));

        if(!this.nodeIsEmpty(currentStackNode)) {
          isEmptySegment = false;
        }
      }
      // console.log(`| isEmptySegment: ${isEmptySegment}`);

      if(isEmptySegment) {
        const prompterSegment = nodeStack[0] as PrompterSegment;
        prompterSegment.title = segmentTitle;
        prompterSegment.children = [];
        return;
      }
    }

    //
    // If we have an existing Script Segment in progress, let's finish it off.
    //
    for(let i = nodeStack.length - 1; i >= 0; i--) {
      const currentStackNode = nodeStack[i];
      // console.log(`Finish stack node '${currentStackNode.type}'`, JSON.parse(JSON.stringify(nodeStack)));

      if(currentStackNode.type === ElementTypes.PARAGRAPH) {
        //
        // If the current node is an empty paragraph, we will drop it.
        //
        // It was probably started by processing a block node like H1 header before we were asked
        // to start a new script segment because of that header.
        //
        if(this.nodeIsEmpty(currentStackNode)) {
          this.parsingStack.pop();
          continue;
        }

        //
        // If the current node is a paragraph with text content, finish this paragraph.
        //
        this.finishBlockScriptNode();
        continue;
      }

      if(currentStackNode.type === ElementTypes.SCRIPT_SEGMENT) {
        //
        // If the last node in this script segment is an empty line (empty paragraph) then we will
        // remove it.
        //
        const childrenCount = currentStackNode.children.length;
        if(childrenCount > 1) {
          const lastChild = currentStackNode.children[childrenCount - 1];
          if(lastChild.type === ElementTypes.PARAGRAPH && this.nodeIsEmpty(lastChild)) {
            //
            // This script segment has more than 1 child, and the last child is an empty paragraph,
            // so we will drop the last child.
            //
            currentStackNode.children = currentStackNode.children.slice(0, -1);
          }
        }

        // We are done adding content to the current script segment. Remove it from the
        // parsingStack. We are about to start a new script segment!
        this.parsingStack.pop();
      }
    }

    //
    // Start a new empty Script Segment
    //
    const existingSegmentsCount = this.results.length;
    const scriptSegment: PrompterElement = {
      type: ElementTypes.SCRIPT_SEGMENT,
      title: segmentTitle,
      number: existingSegmentsCount < 9 ? existingSegmentsCount + 1 : undefined,
      children: [],
    };
    this.parsingStack.push(scriptSegment);
    this.results.push(scriptSegment);
  }

  /**
   * Iterate over the children of a DOM Node and call our parseNode function. This will be called
   * for every node in the HTML DOM tree, though we may have some preprocessing/postprocessing to
   * gather styles/marks from the node being parsed.
   * @param node
   */
  parseChildren(node: Node, depth: number) {
    Array.from(node.childNodes).forEach((childNode) => this.parseNode(childNode, depth));
  }

  /**
   * Parse a Node from the HTML DOM. A node can be an HTML element or a leaf text node (and any
   * other type of node we will ignore).
   * @param node
   * @returns
   */
  parseNode(node: Node, depth: number) {

    //
    // If parsingStack is at the root, we need to start a new Script Segment to contain any further
    // parsed script content.
    //
    if(this.parsingStack.count === 0) {
      this.startScriptSegment();
    }

    //
    // Leaf text nodes are a special case to be handled differently than most HTML Element nodes.
    //
    if(node.nodeType === Node.TEXT_NODE && node.textContent !== null) {
      //
      // A TEXT_NODE in the html markup may contain line breaks and may contain indentation used
      // purely to make the HTML more readable by humans. These linebreaks and superfluous
      // indendation are not desirable in the parsed fluidprompter script content.
      //
      // We must follow the rules for processing white space in HTML.
      //
      // See: https://developer.mozilla.org/en-US/docs/Web/API/Document_Object_Model/Whitespace
      //
      // 1. First, all spaces and tabs immediately before and after a line break are ignored
      // 2. Next, all tab characters are handled as space characters (really we should just replace
      //    tabs with spaces before rule #1 above)
      // 3. Next, line breaks are converted to spaces
      // 4. After that, any space immediately following another space (even across two separate
      //    inline elements) is ignored
      // 5. And finally, sequences of spaces at the beginning and end of an element are removed (we
      //    will handle leading white space in `appendScriptNode()` and trailing white space in
      //    `finishScriptNode()`)
      //
      const cleanedTextContent = node.textContent
        // 2. Convert tab characters to spaces (doing this before replacing white space around line breaks)
        .replace(/[\t]+/g, ' ')
        // 1. Remove all whitespace before/after a line break
        .replace(/[\t\n\r ]*\r?\n[\t\n\r ]*/g, '\n')
        // 3. Convert line breaks to spaces
        .replace(/\r?\n/g, ' ')
        // 4. De-duplicate multiple consecutive spaces - this is a slightly optimistic approach for
        //    now that works but could miss some edge cases.
        .replace(/ +/g, ' ');

      if(cleanedTextContent.length === 0) {
        // console.warn('Dropped empty or whitespace text node!');
        return;
      }

      // console.log(`${'-'.repeat(depth)} Text node '${cleanedTextContent}'`);

      //
      // Gather the set of marks currently applied to text (bold, italic, underline, etc).
      //
      const currentMarks = this.marksCollector.getMarks();

      //
      // Build our leaf text node by assigning the current text marks on a new leaf text node.
      //
      const markedScriptNode = Object.assign({ text: cleanedTextContent }, currentMarks);

      //
      // Add our new leaf text node as a child of the current scriptNode at the top of the
      // parsingStack.
      //
      this.appendScriptNode(markedScriptNode);

      return;
    }

    //
    // If we did not return above, the current node is not a leaf text node. We are now only
    // interested in HTML elements and not interested in HTML comment nodes or CDATA nodes.
    // If the current node is not an HTML element, we want to ignore it and return early.
    //
    if(node.nodeType !== Node.ELEMENT_NODE) {
      // console.log(`${'-'.repeat(depth)} Ignore node '${node.nodeType}'`);
      return;
    }

    //
    // There are some elements we always want to ignore when parsing HTML as a prompter script.
    // Ignored elements include <script> tags or <style> tags which we should just skip over.
    //
    if(IGNORED_HTML_TAGS.indexOf(node.nodeName) >= 0) {
      return;
    }

    //
    // If we have a `data-slate-fragment` attribute within the pasted HTML, then the clipboard data
    // was copied from a slate editor and we might want to handle the pasting differently.
    //
    const slateFragment = (node as Element).getAttribute('data-slate-fragment');
    if(slateFragment) {
      const decoded = decodeURIComponent(window.atob(slateFragment));
      this.slateFragment = JSON.parse(decoded) as Descendant[];

      // console.warn('Clipboard data from Slate!', this.slateFragment);
    }

    //
    // We now know the current node is an HTML element and need to parse the current node in the
    // tree.
    //
    // console.log(`${'-'.repeat(depth)} ${node.nodeName} node`);

    //
    // Collect inline CSS based text marks on this node.
    //
    const {
      isHiddenNode,
      isBlockNode,
      styleMarks,
    } = this.collectNodeStyles(node);

    //
    // If the current node is hidden, we will ignore it and any children of it.
    //   ex: CSS `display: none`, CSS `visibility: hidden`, attribute `aria-hidden=true`
    //
    if(isHiddenNode) {
      return;
    }

    //
    // Push marks resulting from inline styles
    //
    this.marksCollector.pushMarks(styleMarks);

    //
    // Figure out if the current node is a block element as we will translate all blocks into
    // paragraphs for the purpose of a teleprompter script.
    //
    let blockElementType: ElementTypes | undefined;
    if(isBlockNode) {
      switch(node.nodeName) {
        // case 'PRE':
        //   blockElementType = ElementTypes.PRE_BLOCK;
        //   break;
        // case 'BLOCKQUOTE':
        //   blockElementType = ElementTypes.BLOCK_QUOTE;
        //   break;
        case 'DIV':
        case 'P':
        default:
          blockElementType = ElementTypes.PARAGRAPH;
          break;
      }

      if(blockElementType) {
        this.startBlockScriptNode(blockElementType);
      }
    }

    //
    // Check if our lookup table has an explicit handler for this node type, otherwise, use the
    // default handler for an unknown node.
    //
    const handlerResult = Object.hasOwn(HTML_ELEMENT_HANDLER, node.nodeName)
      ? HTML_ELEMENT_HANDLER[node.nodeName](this, node, depth)
      : DEFAULT_HTML_ELEMENT_HANDLER(this, node, depth);

    //
    // If we are parsing a block node, finish the current paragraph now.
    //
    if(isBlockNode && blockElementType && !handlerResult?.dropCurrentBlockNode) {
      this.finishBlockScriptNode();
    }

    //
    // Pop marks resulting from inline styles
    //
    this.marksCollector.popMarks(styleMarks);
  }

  /**
   * Parse an HTML string into an HTML DOM tree, then parse the DOM tree by recursively iterating
   * over the DOM tree to parse each DOM node.
   * @param html string
   * @returns Descendant[] FluidPrompter Script Fragement
   */
  async parseHtml(html: string): Promise<Descendant[]> {
    //
    // Parse the HTML string into an HTML DOM that we can iterate.
    //
    const htmlDom = new DOMParser().parseFromString(html, 'text/html');
    // console.log('HtmlScriptParser.parse() successfully parsed HTML string', htmlDom);
    this.logger.trace({
      htmlDom: htmlDom.body,
    }, 'HtmlScriptParser.parse() successfully parsed HTML string');

    //
    // Recursively iterate through the HTML DOM and parse out supported elements as a FluidPrompter
    // script.
    //
    this.parseNode(htmlDom.body, 1);

    if(!this.results) {
      throw new Error('Html parser didn\'t produce any script content.');
    }

    //
    // Prefer any parsed slateFragment if found while parsing HTML.
    //
    if(this.slateFragment.length) {
      return this.slateFragment;
    }

    this.logger.debug({
      htmlString: html,
      htmlDom: htmlDom.body,
      results: this.results,
    }, 'HtmlScriptParser.parse() Results');

    return this.results;
  }

  /**
   * Handler for FileReader load event.
   * @param e
   */
  private async onFileLoad(e: ProgressEvent<FileReader>) {

    const fileResult = e.target?.result;

    if(!(typeof fileResult === 'string')) {
      throw new Error('HTML must be loaded as string');
    }

    let scriptChildren: Descendant[] = [{
      type: ElementTypes.SCRIPT_SEGMENT,
      children: [{
        type: ElementTypes.PARAGRAPH,
        children: [{
          text: 'Error loading script file.',
        }]
      }]
    }];

    try {
      //
      // Parse the DOCX file as plain text (strip formatting)
      //
      // const parseResults = await this.parseFileAsText(fileResult);
      scriptChildren = await this.parseHtml(fileResult);
    } catch(err) {
      if(this._rejectPromise) {
        this._rejectPromise(err);
      }
    }

    //
    // Surround the script content with our start element and end element.
    //
    const scriptRoot = {
      type: 'script',
      children: [{
        type: ElementTypes.STARTSCRIPT,
        children: [{ text: '' }]
      },
      ...scriptChildren,
      {
        type: ElementTypes.ENDSCRIPT,
        children: [{ text: '' }]
      }]
    };

    //
    // We have finished parsing.
    //
    if(this._resolvePromise) {
      this._resolvePromise(scriptRoot);
    }
  }

  parseFile(scriptFile: File): Promise<astNode> {
    const promise = new Promise<astNode>((resolve, reject) => {

      const fileReader = new FileReader();
      fileReader.addEventListener('load', this.onFileLoad.bind(this));

      //
      // Save a reference to the resolver function so we can resolve the promise when asyncronous
      // parsing operations have completed.
      //
      this._resolvePromise = resolve;
      this._rejectPromise = reject;

      //
      // Safety, just in case...
      //
      setTimeout(() => {
        reject(new Error('promise parseFile() Timeout'));
      }, 8000);

      fileReader.readAsText(scriptFile);
    });

    return promise;
  }
}

export default HtmlScriptParser;