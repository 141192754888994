import { useCallback, useRef } from 'react';
import { useMessageHandler } from '../../controllers/AppController';

// Slate Imports
import { Editor, Text, BaseRange, Node, NodeEntry, Transforms } from 'slate';
import { ReactEditor, withReact, useFocused } from 'slate-react';
import { PrompterEditor, PrompterText, PrompterTextMarkKeys, PrompterTextMarks, PrompterTextMarkValue } from '../../models/EditorTypes';

import { IViewportFuncs } from '../PrompterViewport/usePrompterViewportFuncs';

/**
 * Returns true if any node within the provided selection range of the provided Slate editor contains the mark.
 * @param editor Slate editor instance
 * @param selection The selection range we want to toggle a mark on
 * @param mark The mark name such as 'bold' or 'italic'
 * @returns
 */
const isFormatActive = (editor: PrompterEditor, selection: BaseRange, mark: string) => {
  const nodesMatchedWithFormat = Editor.nodes(editor, {
    at: selection,
    match: (n: Node) => {
      const node = n as PrompterText;
      return node[mark] === true;
    },
    mode: 'all',
  });

  const firstMatchedNode = nodesMatchedWithFormat.next();
  return firstMatchedNode && firstMatchedNode.value;
};

export function getActiveStyles(editor: PrompterEditor) {
  // return new Set(Object.keys(Editor.marks(editor) ?? {}));

  //
  // Collect the set of marks on the current editor selection range.
  //
  const selectionActiveMarks: PrompterTextMarks = {};
  if(editor.selection) {
    //
    // Enumerate all text nodes within the current editor selection.
    const nodesMatchedWithFormat = Editor.nodes<PrompterText>(editor, {
      at: editor.selection,
      match: (node) => Text.isText(node),
      mode: 'all',
    });

    //
    // Iterate over all text nodes within the current editor selection so that we can evaluate
    // which formatting 'marks' are currently active.
    let nextLeafNode: IteratorResult<NodeEntry<PrompterText>, void>;
    do {
      nextLeafNode = nodesMatchedWithFormat.next();
      if (!nextLeafNode.done) { // Using Type discrimination to make sure we have a value: https://github.com/microsoft/TypeScript/issues/33353
        const [node, nodePath] = nextLeafNode.value;
        // console.log(`Selection Node [${nodePath}]: `, node);

        selectionActiveMarks.bold = selectionActiveMarks.bold || node.bold;
        selectionActiveMarks.italic = selectionActiveMarks.italic || node.italic;
        selectionActiveMarks.underline = selectionActiveMarks.underline || node.underline;
        selectionActiveMarks.strike = selectionActiveMarks.strike || node.strike;
        selectionActiveMarks.highlight = selectionActiveMarks.highlight || node.highlight;
        selectionActiveMarks.code = selectionActiveMarks.code || node.code;
      }
    } while (!nextLeafNode.done);
  }

  return selectionActiveMarks;
}

export function toggleStyle(editor: PrompterEditor, style: PrompterTextMarkKeys, value?: PrompterTextMarkValue): boolean {
  const proposedValue = value === undefined ? true : value;
  const activeStyles = getActiveStyles(editor);
  if (
    activeStyles[style] === proposedValue
      || (value === undefined && activeStyles[style])
  ) {
    Editor.removeMark(editor, style);
    return false;
  } else {
    Editor.addMark(editor, style, proposedValue);
    return true;
  }
}

const clearStyle = (editor: PrompterEditor, style: PrompterTextMarkKeys) => {
  Editor.removeMark(editor, style);
};

const clearMarks = (editor: PrompterEditor, selection: BaseRange) => {
  Transforms.setNodes<PrompterText>(
    editor,
    { bold: undefined, italic: undefined, underline: undefined, strike: undefined, highlight: undefined, code: undefined },
    {
      at: selection,
      match: Text.isText,
      split: true
    }
  );
};

export interface FormatCommands {
  toggleBold: () => void;
  toggleItalic: () => void;
  toggleUnderline: () => void;
  toggleStrikethrough: () => void;
  toggleHighlight: (color?: string) => void;
  setHighlight: (color?: string) => void;
  clearHighlight: () => void;
  clearFormatting: () => void;
}

const useEditorFormatCommandHandlers = (editor: PrompterEditor, viewportFuncs: IViewportFuncs): FormatCommands => {

  const toggleBold = useCallback(() => {
    if(editor.selection) {
      toggleStyle(editor, 'bold');
    }
  }, [editor]);
  useMessageHandler('prompter.editor.format.togglebold', toggleBold);

  const toggleItalic = useCallback(() => {
    if(editor.selection) {
      toggleStyle(editor, 'italic');
    }
  }, [editor]);
  useMessageHandler('prompter.editor.format.toggleitalic', toggleItalic);

  const toggleUnderline = useCallback(() => {
    if(editor.selection) {
      toggleStyle(editor, 'underline');
    }
  }, [editor]);
  useMessageHandler('prompter.editor.format.toggleunderline', toggleUnderline);

  const toggleStrikethrough = useCallback(() => {
    if(editor.selection) {
      toggleStyle(editor, 'strike');
    }
  }, [editor]);
  useMessageHandler('prompter.editor.format.togglestrike', toggleStrikethrough);

  const lastHighlightColor = useRef<string>('#ffff00');

  const toggleHighlight = useCallback((color?: string) => {
    const highlightColor = color === undefined ? lastHighlightColor.current : color;
    if(editor.selection) {
      const didHighlight = toggleStyle(editor, 'highlight', highlightColor);
      if(didHighlight) {
        lastHighlightColor.current = highlightColor;
      }
    }
  }, [editor, lastHighlightColor]);
  // useMessageHandler('prompter.editor.format.togglehighlight', toggleHighlight);

  const setHighlight = useCallback((color?: string) => {
    const highlightColor = color === undefined ? '#ffff00' : color;
    if(editor.selection) {
      Editor.addMark(editor, 'highlight', highlightColor);
      lastHighlightColor.current = highlightColor;
    }
  }, [editor]);

  const clearHighlight = useCallback(() => {
    if(editor.selection) {
      clearStyle(editor, 'highlight');
    }
  }, [editor]);

  const clearFormatting = useCallback(() => {
    if(editor.selection) {
      clearMarks(editor, editor.selection);
    }
  }, [editor]);
  useMessageHandler('prompter.editor.format.clear', clearFormatting);

  return {
    toggleBold,
    toggleItalic,
    toggleUnderline,
    toggleStrikethrough,
    toggleHighlight,
    setHighlight,
    clearHighlight,
    clearFormatting,
  };
};

export default useEditorFormatCommandHandlers;