import HtmlScriptParser, { DEFAULT_HTML_ELEMENT_HANDLER } from './HtmlScriptParser';

const shouldCreateNewSegmentForHeader = (headerLevel: number, parser: HtmlScriptParser): boolean => {
  //
  // If our parser options were not set to create script segments for H1 headers, then return
  // false;
  //
  if(
    headerLevel === 1
      && !parser.config.CreateSegmentsForH1
      && !parser.config.CreateSegmentsForAllHeaders
  ) {
    return false;
  }

  //
  // If our parser options were not set to create script segments for H2-H6 headers, then return
  // false;
  //
  if(
    headerLevel > 1
      && !parser.config.CreateSegmentsForAllHeaders
  ) {
    return false;
  }

  return true;
};

const parseHeaderElement = (headerLevel: number, parser: HtmlScriptParser, node: Node, depth: number) => {
  //
  // If the parser was not configured to generate script segments for each H1 header or for all
  // headers, then we will just use the DEFAULT_HTML_ELEMENT_HANDLER.
  //
  if(shouldCreateNewSegmentForHeader(headerLevel, parser) === false) {
    return DEFAULT_HTML_ELEMENT_HANDLER(parser, node, depth);
  }

  //
  // If we didn't return above, then we want to start a new script segment as a result of this html
  // header element.
  //
  const headerEl = node as HTMLHeadingElement;
  parser.startScriptSegment(headerEl.innerText);

  return {
    dropCurrentBlockNode: true,
  };
};

export default parseHeaderElement;