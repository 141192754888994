import {
  PrompterTextMarkKeys,
  PrompterTextMarks,
  PrompterTextMarkValue,
} from '../../models/EditorTypes';

export class PrompterTextMarksCollector {
  marks: Map<PrompterTextMarkKeys, PrompterTextMarkValue[]> = new Map<PrompterTextMarkKeys, PrompterTextMarkValue[]>();

  pushMark(mark: PrompterTextMarkKeys, value: PrompterTextMarkValue) {
    if(!mark) {
      return;
    }

    const existingValues = this.marks.get(mark);
    if(!existingValues) {
      this.marks.set(mark, [value]);
      return;
    }

    this.marks.set(mark, [...existingValues, value]);
  }

  pushMarks(textMarks: PrompterTextMarks) {
    for (const [key, value] of Object.entries(textMarks)) {
      this.pushMark(key as PrompterTextMarkKeys, value);
    }
  }

  popMark(mark: PrompterTextMarkKeys, value: PrompterTextMarkValue) {
    if(!mark) {
      return;
    }

    const existingValues = this.marks.get(mark);
    if(!existingValues) {
      return;
    }

    if(existingValues.length <= 1) {
      this.marks.delete(mark);
      return;
    }

    this.marks.set(mark, existingValues.slice(0, -1));
  }

  popMarks(textMarks: PrompterTextMarks) {
    for (const [key, value] of Object.entries(textMarks)) {
      this.popMark(key as PrompterTextMarkKeys, value);
    }
  }

  getMarks(): PrompterTextMarks {
    const consolidatedMarks: PrompterTextMarks = {};

    for (const [key, value] of this.marks.entries()) {
      const lastValue = value[value.length - 1];

      // Passive unassigned value
      if(lastValue === undefined) {
        continue;
      }

      // Active empty value - should clear any previously set value
      if(lastValue === null || lastValue === '') {
        delete consolidatedMarks[key as PrompterTextMarkKeys];
        continue;
      }

      consolidatedMarks[key as PrompterTextMarkKeys] = lastValue;
    }

    return consolidatedMarks;
  }
}