import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { zaraz } from 'zaraz-ts';

// import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Alert from '@mui/material/Alert';

import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Grid from '@mui/material/Grid';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import useFeatureFlagsStore from '../../state/FeatureFlagsStore';
import useApplicationContext from '../../hooks/useApplicationContext';
import { IUserProfile } from '@fluidprompter/core';

import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const HeaderButton = styled(Button)(({ theme }) => ({
  fontSize: '1rem',
  minWidth: 'fit-content',
  whiteSpace: 'nowrap',
  margin: theme.spacing(2, 0, 0.5),
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 1),
  },
}));

//
const PricingToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto auto auto auto',
  gridGap: theme.spacing(1),
  padding: 1,
  [theme.breakpoints.up('sm')]: {
    gridGap: theme.spacing(2),
  },
  '& .MuiToggleButtonGroup-grouped': {
    padding: '0.25em 0.75em',
    color: '#000',
    background: '#fff',
    borderColor: 'rgba(0, 0, 0, 0.12)',
    borderWidth: '1px 1px 1px 1px',
    borderRadius: '999px',
    boxShadow:'1px 1px 10px 1px #d4d4d4',
    fontWeight: 'bold',
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      padding: '0.25em 1em',
    },
    '&:hover': {
      // borderColor: '#000',
      background: theme.palette.grey[100],
    },
    '&.Mui-disabled': {
      border: 0,
    },
    '&.Mui-selected': {
      color: '#000',
      background: 'rgb(209,250,229)', //'#209cee',
      borderColor: 'rgb(5, 150, 105)',  // #000
      '&:hover': {
        background: 'rgb(209,250,229)', //'#209cee',
        borderColor: 'rgb(5, 150, 105)',  // #000
      },
    },
    '&:not(:first-of-type)': {
      //borderRadius: theme.shape.borderRadius,
      marginLeft: 0,
      //borderLeft: '1px solid rgba(0, 0, 0, 0.12);',
      borderTopLeftRadius: 'inherit',
      borderBottomLeftRadius: 'inherit',

      borderColor: 'rgba(0, 0, 0, 0.12)', // Overrides style that makes all buttons except the first one left border transparent
      borderWidth: '1px 1px 1px 1px',
      borderRadius: '999px',

      '&.Mui-selected': {
        color: '#000',
        background: 'rgb(209,250,229)', //'#209cee',
        borderColor: 'rgb(5, 150, 105)',  // #000
        '&:hover': {
          background: 'rgb(209,250,229)', //'#209cee',
          borderColor: 'rgb(5, 150, 105)',  // #000
        },
      },
    },
    '&:not(:last-of-type)': {
      borderTopRightRadius: 'inherit',
      borderBottomRightRadius: 'inherit',

      borderWidth: '1px 1px 1px 1px',
      borderRadius: '999px',
      //borderRadius: theme.shape.borderRadius,
    },
    // '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
    //   boxShadow: 'inherit',
    // },
  },
}));

const StyledChip = styled(Chip)((/*{ theme }*/) => ({
  marginLeft: '8px',
  marginBottom: '5px',
  borderRadius: '4px',
  '& .MuiChip-label': {
    paddingLeft: '6px',
    paddingRight: '6px',
  },
}));

const FeatureList = styled(List)<{ component?: React.ElementType }>({
  '& .MuiListItemButton-root': {
    textDecorationStyle: 'underline #000 dashed',
    paddingLeft: 6,
    paddingRight: 6,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 8,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 24,
  },
});

const config = {
  eventEnabled: false
};

interface IPlanPriceDetails {
  currency: string;
  price: number;
  priceSubtitle: string;
  savings?: string;
  testSku: string;
  checkoutSku: string;
  terms: string;
}

interface IPlanPriceFeatures {
  included: boolean;
  summary?: string;
  description?: string;
}

enum PlanPriceDataKeys {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
  EVENT = 'event',
}
type PricingType = 'monthly' | 'yearly' | 'event';

interface IPlanPriceData {
  monthly: IPlanPriceDetails;
  yearly: IPlanPriceDetails;
  event: IPlanPriceDetails;
  features: IPlanPriceFeatures[];
}
type TPlanPriceData = {
  [key in PlanPriceDataKeys]: IPlanPriceDetails;
} & IPlanPriceData;

interface IPriceData {
  studio: TPlanPriceData;
  pro: TPlanPriceData;
  free: TPlanPriceData;

  [index: string]: TPlanPriceData;
}
const priceData: IPriceData = {
  studio: {
    monthly: {
      currency: 'US$',
      price: 29,
      priceSubtitle: '/mo /user',
      testSku: 'price_1MdGXWE4AiWOHBTNPrqoHGub',      // Test Mode Product Id
      checkoutSku: 'price_1MdGheE4AiWOHBTNoASClayH',  // Live Mode Product Id
      terms: 'Excl. tax, billed monthly',
    },
    yearly: {
      currency: 'US$',
      price: 279,
      priceSubtitle: '/yr /user',
      savings: 'SAVE $69!',
      testSku: 'price_1MdGXqE4AiWOHBTNYlNgoTTa',
      checkoutSku: 'price_1MdGheE4AiWOHBTNRUHO9jND',
      terms: 'Excl. tax, billed annually',
    },
    event: {
      currency: 'US$',
      price: 49,
      priceSubtitle: '/event /user',
      savings: 'ONE PAYMENT',
      testSku: 'price_1MdGY5E4AiWOHBTNJnlRGwvv',
      checkoutSku: 'price_1MdGheE4AiWOHBTNW5uuHyhX',
      terms: 'Excl. tax, 10-day access, billed once',
    },
    features: [{
      included: false,
      summary: 'Multi-user Account (COMING SOON!)',
      description: 'Share scripts and control across multiple user accounts.',
    }, {
      included: true,
      summary: 'Connect 2+ Hardware Remotes',
      description: 'Connect multiple remote controls at the same time such as a foot pedal and a handheld'
    }, {
      included: true,
      summary: 'Synchronize 3+ Prompters',
      description: 'Connect more than 2 prompter devices at the same time such as 1 laptop + 2 iPads in one session',
    }, {
      included: true,
      summary: 'Unlimited words',
      description: 'No limit on script length.'
    }, {
      included: true,
      summary: 'Priority Email Support',
      description: 'Get prioritized support directly from the app developer!'
    }]
  },
  pro: {
    monthly: {
      currency: 'US$',
      price: 9,
      priceSubtitle: '/mo (single user)',
      testSku: 'price_1Lr2hqE4AiWOHBTNFpGpaDZb',      // Test Mode Price Id
      checkoutSku: 'price_1MdGgTE4AiWOHBTNMiPua4MB',  // Live Mode Price Id
      terms: 'Excl. tax, billed monthly',
    },
    yearly: {
      currency: 'US$',
      price: 89,
      priceSubtitle: '/yr (single user)',
      savings: 'SAVE $19!',
      testSku: 'price_1MdGWTE4AiWOHBTN6lMY6gvJ',
      checkoutSku: 'price_1MdGgTE4AiWOHBTNTf5fw9Vw',
      terms: 'Excl. tax, billed annually',
    },
    event: {
      currency: 'US$',
      price: 19,
      priceSubtitle: '/event (single user)',
      savings: 'ONE PAYMENT',
      testSku: 'price_1MdGWvE4AiWOHBTNvZE5EB6k',
      checkoutSku: 'price_1MdGgTE4AiWOHBTNFV3xHub5',
      terms: 'Excl. tax, 10-day access, billed once',
    },
    features: [{
      included: true,
      summary: 'Connect 1 Hardware Remote',
      description: 'Connect a hardware controller such as a wireless bluetooth remote'
    }, {
      included: true,
      summary: 'Analog Foot-Pedal Controls',
      description: 'Get the most intuitive scrolling speed control using an AirTurn S-6 bluetooth controller with analog pedal input'
    }, {
      included: true,
      summary: 'Synchronize 2 Prompters',
      description: 'Connect two device such a tablet on your prompter hardware and a laptop for easy remote control',
    }, {
      included: true,
      summary: 'Unlimited words',
      description: 'No limit on script length.'
    }]
  },
  free: {
    monthly: {
      currency: 'US$',
      price: 0,
      priceSubtitle: '(single user)',
      testSku: 'price_1LlyvsE4AiWOHBTNLBTGU2kv',
      checkoutSku: 'price_1MdGj2E4AiWOHBTNtAdcFWAu',
      terms: 'No credit card required',
    },
    yearly: {
      currency: 'US$',
      price: 0,
      priceSubtitle: '(single user)',
      testSku: 'price_1LlyvsE4AiWOHBTNLBTGU2kv',
      checkoutSku: 'price_1MdGj2E4AiWOHBTNtAdcFWAu',
      terms: 'No credit card required',
    },
    event: {
      currency: 'US$',
      price: 0,
      priceSubtitle: '(single user)',
      testSku: 'price_1LlyvsE4AiWOHBTNLBTGU2kv',
      checkoutSku: 'price_1MdGj2E4AiWOHBTNtAdcFWAu',
      terms: 'No credit card required',
    },
    features: [{
      included: true,
      summary: 'Single-user Account',
      description: 'Your free account will have only one user associated with it and no team collaboration features.',
    }, {
      included: true,
      summary: 'Keyboard/Mouse Controls',
      description: 'All prompter plans include basic keyboard/mouse controls.'
    }, {
      included: true,
      summary: 'Only 1 Prompter',
      description: 'The free plan includes 1 prompter screen or device. Upgrade to share your prompter on multiple screens or devices.',
    }, {
      included: true,
      summary: '1000 Words Script Limit',
      description: 'The free plan is limited to scripts that are 1000 words or less in length. Upgrade for unlimited length.'
    }]
  }
};

const CreateStripeAccount = () => {
  const theme = useTheme();
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up('md'));

  const {
    appContext,
    patchUserProfile,
  } = useApplicationContext();

  const [ searchParams ] = useSearchParams();

  const checkoutId = searchParams.get('checkout_id');
  const checkoutCancelled = searchParams.get('checkout_cancelled');

  const {
    getApiToken,
    fetchUserProfile,
  } = useApplicationContext();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if(!checkoutId) {
      return;
    }

    //
    // If we have a checkoutId and have still landed on the createStripeAccount view, then we are
    // just waiting for the Stripe Webhook to process. Let's just refresh the user profile in a few
    // seconds to try again.
    //
    const timeoutHandle = window.setTimeout(async () => fetchUserProfile(), 1000);

    return () => {
      window.clearTimeout(timeoutHandle);
    };
  }, [checkoutId, fetchUserProfile]);

  /**
   * Create a free stripe subscription completely without redirecting the user to Stripe checkout
   * @param apiToken FluidPrompter API Token from Auth0
   * @param priceId Stripe priceId for the free plan price
   * @param returnUrl
   * @returns
   */
  const gotoCheckoutFree = async (apiToken: string, priceId: string, returnUrl: URL) => {
    if(!appContext.userProfile) {
      return;
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/stripe/freecheckout?` + new URLSearchParams({
      return_url: returnUrl.toString(),
      priceId,
    }), {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${apiToken}`,
      },
    });

    if(response.status !== 200) {
      // We received an error response.
      alert('Sorry, we encountered an unexpected error. Please contact fluidprompter support.');
      return;
    }

    await zaraz.track('onboarding_checkout_start', {
      fluidprompter_plan: 'free',
    });

    //
    // If successful, we need to patch the user profile to save `fluidprompter_plan`=free
    //
    const userProfile: IUserProfile = { ...appContext.userProfile };
    userProfile.fluidprompter_plan = 'free';

    await patchUserProfile(userProfile);
  };

  const gotoCheckoutPaid = async (fluidprompter_plan: string, apiToken: string, priceId: string, returnUrl: URL) => {

    const response = await fetch(`${process.env.REACT_APP_API_URL}/stripe/getcheckouturl?` + new URLSearchParams({
      return_url: returnUrl.toString(),
      priceId,
    }), {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${apiToken}`,
      },
    });

    if(response.status !== 200) {
      // We received an error response.
      alert('Sorry, we encountered an unexpected error. Please contact fluidprompter support.');
      return;
    }

    // console.log(`Got app API account response from API serverless function`, response);

    const billingPortalSession = await response.json();
    // console.log(billingPortalSession);

    const billingUrl = billingPortalSession.url as string;
    if(billingUrl) {
      await zaraz.track('onboarding_checkout_start', {
        fluidprompter_plan
      });

      // Ecommerce APIs are "special" - they can integrate with GA4, Bing and Facebook ecommerce tracking
      // await zaraz.ecommerce('Checkout Started', {
      //   //
      // });

      window.location.href = billingUrl;
    }
  };

  const gotoCheckout = async (fluidprompter_plan: string, priceId: string) => {
    try {
      setIsSaving(true);

      const token = await getApiToken();

      const returnUrl = new URL(window.location.href);

      // Make sure we don't lose any temporarily enabled feature flags during the login redirects.
      useFeatureFlagsStore.getState().encodeFeatureFlagParams(returnUrl.searchParams);

      switch(fluidprompter_plan) {
        case 'free':
          // Free checkout is optimized for minimum UI, minimum steps
          gotoCheckoutFree(token, priceId, returnUrl);
          break;
        default:
          // Paid checkout will require collecting user payment info and billing address.
          gotoCheckoutPaid(fluidprompter_plan, token, priceId, returnUrl);
          break;
      }
    } catch (e) {
      setIsSaving(false);
      console.error(e);
    }
  };

  const [pricingType, setPricingType] = useState<PricingType>('monthly');
  const handlePricingTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newPricingType: PricingType,
  ) => {
    if (newPricingType !== null) {
      setPricingType(newPricingType);
    }
  };

  return (
    <>
      <DialogContent dividers={true} sx={{ }}>
        {checkoutId && <Alert severity="success"><strong>PROCESSING</strong> — Please wait a few seconds...</Alert>}
        {checkoutCancelled && <Alert severity="error"><strong>Checkout Not Completed</strong> — Please Try Again!</Alert>}
        <Box
          maxWidth={'md'}
          sx={{
            m: '0 auto',
            marginTop: 2,
            marginBottom: 7,
          }}
        >
          <h3 style={{
            textAlign: 'center',
            fontSize: viewportIsLarge ? '1.2rem' : '1rem',
            fontWeight: viewportIsLarge ? 'bold' : '400'
          }}>Let&apos;s finish your FluidPrompter account setup.</h3>
          <Stepper activeStep={1} alternativeLabel>
            <Step>
              <StepLabel>Use Case</StepLabel>
            </Step>
            <Step>
              <StepLabel>Choose Plan</StepLabel>
            </Step>
            <Step>
              <StepLabel>Finish</StepLabel>
            </Step>
          </Stepper>
        </Box>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isSaving}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {/* <h3 style={{
          marginBottom: '2em',
        }}>Choose your FluidPrompter Plan:</h3> */}

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4}>
            <Card
              raised={false}
              elevation={2}
              sx={{
                pt: 3,
                pb: 1,
              }}
            >
              <CardHeader
                title="Starter Plan"
                titleTypographyProps={{
                  //fontWeight: 'bold',
                  lineHeight: '1.25em',
                }}
                subheader="Do the basics, but better"
                subheaderTypographyProps={{
                  height: '1.5em'
                }}
              />
              <Divider variant="middle" />
              <CardContent>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-end"
                  spacing={0.5}
                >
                  <Typography variant="h4">${priceData.free[pricingType].price}</Typography>
                  <Typography variant="subtitle1">{priceData.free[pricingType].priceSubtitle}</Typography>
                </Stack>
                <HeaderButton
                  fullWidth
                  variant="outlined"
                  color="primary"
                  disableRipple={true}
                  onClick={(e) => {
                    e.preventDefault();
                    const freePriceId = (process.env.REACT_APP_ENV === 'production')
                      ? priceData.free[pricingType].checkoutSku
                      : priceData.free[pricingType].testSku;
                    gotoCheckout('free', freePriceId);
                  }}
                >Use Free</HeaderButton>
                <Typography align="center" sx={{
                  fontSize: '0.75rem',
                  color: 'rgba(0, 0, 0, 0.6)',
                  mb: 1,
                }}>{priceData.free[pricingType].terms}</Typography>
                <FeatureList dense disablePadding>
                  {priceData.free.features.map((row: IPlanPriceFeatures, index: number) => (
                    <ListItem disableGutters key={`free-feature-${index}`}>
                      <Tooltip arrow describeChild title={row.description || ''}>
                        <ListItemButton disableGutters disableTouchRipple>
                          <ListItemIcon>
                            <CheckIcon fontSize="medium" color="success" />
                          </ListItemIcon>
                          <ListItemText primary={row.summary} />
                        </ListItemButton>
                      </Tooltip>
                    </ListItem>
                  ))}
                </FeatureList>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={8} position='relative'>
            <Stack direction="row" justifyContent='center' spacing={2} sx={{
              position: 'absolute',
              width: '100%',
              height: '1em',
              top: '0.5em',
            }}>
              <PricingToggleButtonGroup
                exclusive={true}
                value={pricingType}
                onChange={handlePricingTypeChange}
              >
                <ToggleButton
                  value="monthly"
                  color="info"
                >Monthly</ToggleButton>
                <ToggleButton
                  value="yearly"
                  color="info"
                >Yearly&nbsp;<small style={{ color: 'rgb(5, 150, 105)' }}>~20%&nbsp;off</small></ToggleButton>
                {config.eventEnabled && <ToggleButton
                  value="event"
                  color="info"
                >Single Event</ToggleButton>}
              </PricingToggleButtonGroup>
            </Stack>
            <Card elevation={5}
              sx={{
                pt: 3,
                pb: 1,
                overflow: 'visible',
              }}>

              <StyledGrid container>
                <StyledGrid item xs={12} sm>
                  <CardHeader
                    title="Solo Creator"
                    titleTypographyProps={{
                      fontWeight: 'bold',
                      lineHeight: '1.25em',
                    }}
                    subheader="Level up your setup"
                    subheaderTypographyProps={{
                      height: '1.5em'
                    }}
                  />
                  <Divider variant="middle" />
                  <CardContent>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="flex-end"
                      spacing={0.5}
                    >

                      <Typography variant="subtitle1" sx={{ fontSize: '0.875em', lineHeight: '2', color: 'rgba(0, 0, 0, 0.6)' }}>usd</Typography>
                      <Typography variant="h4">${priceData.pro[pricingType].price}</Typography>
                      <Typography variant="subtitle1">{priceData.pro[pricingType].priceSubtitle}</Typography>
                      {priceData.pro[pricingType].savings && <Box><StyledChip label={priceData.pro[pricingType].savings} color="success" size="small" /></Box>}
                    </Stack>
                    <HeaderButton
                      fullWidth
                      variant="contained"
                      color="primary"
                      disableRipple={true}
                      onClick={(e) => {
                        e.preventDefault();
                        const proPriceId = (process.env.REACT_APP_ENV === 'production')
                          ? priceData.pro[pricingType].checkoutSku
                          : priceData.pro[pricingType].testSku;
                        gotoCheckout('solo', proPriceId);
                      }}
                    >Buy Pro</HeaderButton>
                    <Typography align="center" sx={{
                      fontSize: '0.75rem',
                      color: 'rgba(0, 0, 0, 0.6)',
                      mb: 1,
                    }}>{priceData.pro[pricingType].terms}</Typography>
                    <FeatureList dense disablePadding>
                      {priceData.pro.features.map((row: IPlanPriceFeatures, index: number) => (
                        <ListItem disableGutters key={`pro-feature-${index}`}>
                          <Tooltip arrow describeChild title={row.description || ''}>
                            <ListItemButton disableGutters disableTouchRipple disabled={!row.included}>
                              <ListItemIcon>
                                {row.included ? <CheckIcon fontSize="medium" color="success" /> : <ClearIcon fontSize="medium" color="disabled" />}
                              </ListItemIcon>
                              <ListItemText primary={row.summary} />
                            </ListItemButton>
                          </Tooltip>
                        </ListItem>
                      ))}
                    </FeatureList>
                  </CardContent>
                </StyledGrid>
                <Divider orientation="vertical" variant="middle" flexItem sx={{ display: { xs: 'none', sm:'flex' } }}>Or</Divider>
                <StyledGrid item xs={12} sm>
                  <Divider variant="middle" sx={{ display: { xs: 'flex', sm:'none' } }}>Or</Divider>
                  <CardHeader
                    title="Studio Team"
                    titleTypographyProps={{
                      fontWeight: 'bold',
                      lineHeight: '1.25em',
                    }}
                    subheader="Collaborate with your crew"
                    subheaderTypographyProps={{
                      height: '1.5em'
                    }}
                  />
                  <Divider variant="middle" />
                  <CardContent>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="flex-end"
                      spacing={0.5}
                    >
                      <Typography variant="subtitle1" sx={{ fontSize: '0.875em', lineHeight: '2', color: 'rgba(0, 0, 0, 0.6)' }}>usd</Typography>
                      <Typography variant="h4">${priceData.studio[pricingType].price}</Typography>
                      <Typography variant="subtitle1">{priceData.studio[pricingType].priceSubtitle}</Typography>
                      {priceData.studio[pricingType].savings && <Box><StyledChip label={priceData.studio[pricingType].savings} color="success" size="small" /></Box>}
                    </Stack>
                    <HeaderButton
                      fullWidth
                      variant="contained"
                      color="primary"
                      disableRipple={true}
                      onClick={(e) => {
                        e.preventDefault();
                        const studioPriceId = (process.env.REACT_APP_ENV === 'production')
                          ? priceData.studio[pricingType].checkoutSku
                          : priceData.studio[pricingType].testSku;
                        gotoCheckout('team', studioPriceId);
                      }}
                    >Buy Studio</HeaderButton>
                    <Typography align="center" sx={{
                      fontSize: '0.75rem',
                      color: 'rgba(0, 0, 0, 0.6)',
                      mb: 1,
                    }}>{priceData.studio[pricingType].terms}</Typography>
                    <FeatureList dense disablePadding>
                      {priceData.studio.features.map((row: IPlanPriceFeatures, index: number) => (
                        <ListItem disableGutters key={`studio-feature-${index}`}>
                          <Tooltip arrow describeChild title={row.description || ''}>
                            <ListItemButton disableGutters disableTouchRipple disabled={!row.included}>
                              <ListItemIcon>
                                {row.included ? <CheckIcon fontSize="medium" color="success" /> : <ClearIcon fontSize="medium" color="disabled" />}
                              </ListItemIcon>
                              <ListItemText primary={row.summary} />
                            </ListItemButton>
                          </Tooltip>
                        </ListItem>
                      ))}
                    </FeatureList>
                  </CardContent>
                </StyledGrid>
              </StyledGrid>

            </Card>
          </Grid>

          <Grid item xs={12}>
            <Typography align="center">The free version contains all essential functions.</Typography>
            <Typography align="center">Subscription plans are billed monthly or yearly. Cancel any time.</Typography>
            {config.eventEnabled && <Typography align="center">Single event licenses are billed once and stay active for 10 days.</Typography>}
          </Grid>
        </Grid>
        {/*
        <Divider style={{
          marginTop: 2, marginBottom: 2,
        }}/>
        <HeaderButton
          variant="outlined"
          size="small"
          //onClick={handleComparePlans}
        >Compare Plans</HeaderButton>
        */}
      </DialogContent>
    </>
  );
};

export default CreateStripeAccount;