import BaseControlMessage from './BaseControlMessage';
export const SCRIPTSTATEMESSAGE_KEY = 'script.state';
/**
 * Represents the ScriptStateMessage type.
 */
export class ScriptStateMessage extends BaseControlMessage {
    constructor(args) {
        super();
        this.type = SCRIPTSTATEMESSAGE_KEY;
        const { scriptNodes, selection, lastChangedTimestamp, hasUnsavedChanges, applyOnSender, } = args;
        this.scriptNodes = scriptNodes;
        this.selection = selection;
        this.lastChangedTimestamp = lastChangedTimestamp;
        this.hasUnsavedChanges = (hasUnsavedChanges === true);
        this.applyOnSender = applyOnSender;
    }
    static fromJson(data) {
        if (data.type !== SCRIPTSTATEMESSAGE_KEY) {
            throw new SyntaxError('Invalid JSON for ScriptStateMessage.');
        }
        const input = data;
        const instance = new ScriptStateMessage({
            scriptNodes: input.scriptNodes,
            selection: input.selection,
            lastChangedTimestamp: input.lastChangedTimestamp,
            hasUnsavedChanges: (input.hasUnsavedChanges === true),
        });
        instance.deserializeSenderInfo(input.sender);
        return instance;
    }
    toJSON() {
        return {
            type: this.type,
            sender: this.sender?.toJSON(),
            scriptNodes: this.scriptNodes,
            selection: this.selection,
            lastChangedTimestamp: this.lastChangedTimestamp,
            hasUnsavedChanges: this.hasUnsavedChanges,
        };
    }
}
ScriptStateMessage.MESSAGE_NAME = SCRIPTSTATEMESSAGE_KEY;
