import { useCallback } from 'react';

import { Editor, Descendant, Node as SlateNode, Transforms, Element } from 'slate';
// import { ReactEditor, withReact } from 'slate-react';
import { jsx } from 'slate-hyperscript';

import { ElementTypes, PrompterTextMarks } from '../../models/EditorTypes';
import HtmlScriptParser from '../../utils/scripts/html/HtmlScriptParser';

type ELEMENT_TAGS_LOOKUP = {
  [key: string]: (el: Node) => Element;
}
const ELEMENT_TAGS: ELEMENT_TAGS_LOOKUP = {
  A: (el: Node) => ({ type: ElementTypes.HYPERLINK, url: (el as HTMLAnchorElement).getAttribute('href'), children: [] }),
  BLOCKQUOTE: () => ({ type: ElementTypes.BLOCK_QUOTE, children: [] }),
  // H1: () => ({ type: 'heading-one' }),
  // H2: () => ({ type: 'heading-two' }),
  // H3: () => ({ type: 'heading-three' }),
  // H4: () => ({ type: 'heading-four' }),
  // H5: () => ({ type: 'heading-five' }),
  // H6: () => ({ type: 'heading-six' }),
  // IMG: (el: HTMLElement) => ({ type: 'image', url: el.getAttribute('src') }),
  // LI: () => ({ type: 'list-item' }),
  // OL: () => ({ type: 'numbered-list' }),
  P: () => ({ type: ElementTypes.PARAGRAPH, children: [] }),
  PRE: () => ({ type: ElementTypes.CODE_BLOCK, children: [] }),
  // UL: () => ({ type: 'bulleted-list' }),
};

type TEXT_TAGS_LOOKUP = {
  [key: string]: () => PrompterTextMarks;
}
// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS: TEXT_TAGS_LOOKUP = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strike: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strike: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
};

export const deserializeDomNodeTree = (el: Node): (string | SlateNode | null)[] | string | null | Descendant[] | Descendant => {
  if (el.nodeType === Node.TEXT_NODE) {
    // Node.TEXT_NODE (3) = The actual Text inside an Element or Attr.
    return el.textContent;
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    //
    // Node.ELEMENT_NODE (1) = An Element node like <p> or <div>.
    // We are returning null if the current node is not an element
    //
    return null;
  } else if (el.nodeName === 'BR') {
    return '\n';
  }

  const { nodeName } = el;
  let parent = el;

  if (
    nodeName === 'PRE' &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === 'CODE'
  ) {
    parent = el.childNodes[0];
  }
  let children = Array.from(parent.childNodes)
    .map(deserializeDomNodeTree)
    .flat();

  if (children.length === 0) {
    children = [{ text: '' }];
  }

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children);
  }

  // if (ELEMENT_TAGS[nodeName]) {
  if (Object.hasOwn(ELEMENT_TAGS, nodeName)) {
    const attrs = ELEMENT_TAGS[nodeName](el);
    return jsx('element', attrs, children);
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName]();
    return children.map(child => jsx('text', attrs, child));
  }

  return children;
  //
};

function useEditorClipboard(editor: Editor) {

  const onPaste: React.ClipboardEventHandler<HTMLDivElement> = useCallback(async (e: React.ClipboardEvent<HTMLDivElement>) => {
    //
    // # Copying from Slate (ie: from FluidPrompter)
    // Macbook Chrome: [text/plain,text/html,application/x-slate-fragment]
    //
    // # Copying from Google Docs
    // Macbook Chrome: [
    //   text/plain,
    //   text/html,
    //   application/x-vnd.google-docs-document-slice-clip+wrapped,
    //   application/x-vnd.google-docs-internal-clip-id
    // ]
    //
    const availableDataTypes = e.clipboardData.types;   // types: application/x-slate-fragment,text/html,text/plain
    //
    console.log(`onPaste handler fired [${availableDataTypes}]`, e.clipboardData);

    let clipboardFragment: SlateNode[] | undefined;
    if(availableDataTypes.indexOf('text/html') >= 0) {

      const clipboardHtml = e.clipboardData.getData('text/html');

      const parser = new HtmlScriptParser();

      const parsedContent = await parser.parseHtml(clipboardHtml);

      if(parsedContent.length === 1 && parsedContent[0].type === ElementTypes.SCRIPT_SEGMENT) {
        clipboardFragment = parsedContent[0].children;
      } else {
        clipboardFragment = parsedContent;
      }
    }

    //
    // If we do not yet have a parsed `clipboardFragment` value, then either html was not available
    // or failed to parse. Let's see if we can parse plain text.
    //
    if (!clipboardFragment && availableDataTypes.indexOf('text/plain') >= 0) {
      const clipboardText = e.clipboardData.getData('text/plain');

      //
      // New clipboard paste handler honours line breaks and splits pasted content into a collection
      // of paragraphs.
      //
      const clipboardTextLines = clipboardText.split(/\r?\n/);

      clipboardFragment = clipboardTextLines.map<SlateNode>(textLine => ({
        type: ElementTypes.PARAGRAPH,
        children: [{
          text: textLine,
        }]
      }));
    }

    //
    // If we have parsed the clipboard data ourselves, then lets prevent the browser's default
    // paste event handling and insert our parsed content.
    //
    if(clipboardFragment && clipboardFragment.length) {
      e.preventDefault();
      e.stopPropagation();

      // TODO, do we need to better handle unwrapping/wrapping the current selection in the editor
      // before we insert our fragment?
      Transforms.insertFragment(editor, clipboardFragment);
    }
  }, [editor]);

  return {
    onPaste,
  };
}

export default useEditorClipboard;