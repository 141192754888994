import { Editor, NodeEntry, Range, Path, Node } from 'slate';
import { PrompterEditor, ElementTypes } from '../../models/EditorTypes';

import ensureScriptHasStartAndEndElements from './Normalization/ensureScriptHasStartAndEndElements';
import ensureNoNestedScriptSegments from './Normalization/ensureNoNestedScriptSegments';
import ensureScriptSegmentHasChild from './Normalization/ensureScriptSegmentHasChild';
import numberScriptSegments from './Normalization/numberScriptSegments';

type NormalizerFn = (editor: PrompterEditor, node: Node, nodePath: Path) => boolean;
const normalizers = new Map<string, NormalizerFn>([
  ['ensureScriptHasStartAndEndElements', ensureScriptHasStartAndEndElements],
  ['ensureNoNestedScriptSegments', ensureNoNestedScriptSegments],
  ['ensureScriptSegmentHasChild', ensureScriptSegmentHasChild],
  ['numberScriptSegments', numberScriptSegments],
]);

const withSlateCustomizations = <T extends PrompterEditor>(editor: T): T => {
  const { isVoid, isInline, insertBreak, deleteBackward, deleteForward, insertData, normalizeNode  } = editor;

  editor.isVoid = (element) => {
    return [ElementTypes.STARTSCRIPT, ElementTypes.PAUSE, ElementTypes.ENDSCRIPT, 'kbd', ElementTypes.BROLL, ElementTypes.IMAGE].includes(element.type) || isVoid(element);
  };

  editor.isInline = (element) => {
    // Our custom FluidPrompter nodes that are inline elements.
    if(
      //
      // Some of FluidPrompter's code appears to sometimes call isInline() with an element that
      // has no type or text. I wonder if this is a bug in `Text.isText()` not returning true for
      // empty leaf nodes?
      //
      // For now, let's just use `element?.type` to protect ourselves!
      //
      // usePrompterContentResizedHandler:
      // isBlock = !(Text.isText(currentNode) || Editor.isInline(editor, currentNode));
      //
      // useRecalculateSegmentPositionsHandler:
      // isInline = Text.isText(prompterDescendant) || Editor.isInline(editor, prompterDescendant);
      //
      [
        'kbd',
        ElementTypes.HYPERLINK,
        'link'
      ].includes(element.type)
    ) {
      return true;
    }

    // Fall back to the built-in method for testing isInline.
    return isInline(element);
  };

  editor.insertBreak = () => {

    // Find the native DOM element from a Slate `node`.
    /*
     * NOT NECESSARY - we are enforcing the start of script and end of script nodes in normalizeNode function now.
     *
    if(isStartNodeOrEndNode(editor)) {
      //ReactEditor.toDOMNode(editor, node);
      alert('You can\'t press enter on a void node!');
      return;
    }*/
    //console.log(`editor.insertBreak current selection:`, editor.selection);

    if (!editor.selection || !Range.isCollapsed(editor.selection)) {
      return insertBreak();
    }

    const selectedNodePath = Path.parent(editor.selection.anchor.path);
    const selectedNode = Node.get(editor, selectedNodePath);
    //console.log(`Selected node when enter pressed:`, selectedNode);
    if (Editor.isVoid(editor, selectedNode)) {
      Editor.insertNode(editor, {
        type: ElementTypes.PARAGRAPH,
        children: [{ text: '' }],
      });
      return;
    }

    // If the current selection is a void type, suppress the default behavior and insert a break after the currently selected void type.
    insertBreak();
  };

  // deleteBackward: (unit: 'character' | 'word' | 'line' | 'block') => void
  editor.deleteBackward = (unit) => {

    /*
    if (editor.selection && Range.isCollapsed(editor.selection) && (editor.selection.anchor.offset === 0)) {
      // We are at the very start of a node, therefor to check if we can backspace, we need to check the previous node for eligbility.
      // Editor.previous: <T extends Node>(editor: Editor, options?: EditorPreviousOptions<T>) => NodeEntry<T> | undefined;
      const nodeEntry = Editor.previous(editor, {
        at: editor.selection,
        match: (node, path): boolean => {
          return true;
        },
        mode: 'all',
        voids: true,
      });

      if(nodeEntry) {
        const [previousNode, previousPath] = nodeEntry;
        console.log('Previous Node', previousNode, previousPath);

        if(previousNode) {
          const previousNodeAsAny = previousNode as any;
          if(previousNodeAsAny.type === ElementTypes.STARTSCRIPT) {
            alert('you aren\'t allowed to perform a backspace right now');
            return;
          }
        }
      }
    } */

    // Editor.next: <T extends Descendant>(editor: Editor, options?: EditorNextOptions<T>) => NodeEntry<T> | undefined;
    // Editor.previous: <T extends Node>(editor: Editor, options?: EditorPreviousOptions<T>) => NodeEntry<T> | undefined;
    // Editor.previous

    //console.log('deleteBackward');
    deleteBackward(unit);
  };

  // deleteForward: (unit: 'character' | 'word' | 'line' | 'block') => void
  editor.deleteForward = (unit) => {
    //console.log('deleteForward');
    deleteForward(unit);
  };

  editor.insertData = (data) => {
    // const html = data.getData('text/html');

    console.log('insertData', data);

    /*
    if (html) {
      const parsed = new DOMParser().parseFromString(html, 'text/html')
      const fragment = deserialize(parsed.body)
      Transforms.insertFragment(editor, fragment)
      return
    }
    */

    insertData(data);
  };

  /* normalizeNode is called from the deepest node changed up the tree to the root. It's job is to
   * fix any errors resulting from browser contentEditable. For example two consecutive text nodes
   * with the exact same marks can be merged into 1 node.
   */
  editor.normalizeNode = (entry: NodeEntry<Node>) => {
    const [ node, nodePath ] = entry;

    //
    // Iterate over our editor normalization functions that enforce certain schema constraints on
    // the script document structure.
    //
    for (const [normalizerName, normalizer] of normalizers) {
      try {
        const changeWasMade = normalizer(editor, node, nodePath);
        if (changeWasMade) return;
      }
      catch (e) {
        console.log(`Error at ${normalizerName}`, e );
        // console.log(editor.children[0,0]);
      }
    }

    //
    // Fall back to the original `normalizeNode` to enforce default editor constraints.
    //
    normalizeNode(entry);
  };

  return editor;
};

export default withSlateCustomizations;