import { Transforms, Path, Node } from 'slate';
import { ElementTypes, PrompterEditor, PrompterElement } from '../../../models/EditorTypes';

const ensureNoNestedScriptSegments = (editor: PrompterEditor, node: Node, nodePath: Path): boolean => {
  const nodePrompterElement = node as PrompterElement;
  if(nodePrompterElement.type !== ElementTypes.SCRIPT_SEGMENT) {
    return false;
  }

  const nestedScriptSegmentIndex = nodePrompterElement.children
    .findIndex(child => child.type === ElementTypes.SCRIPT_SEGMENT);
  if(nestedScriptSegmentIndex >= 0) {
    Transforms.liftNodes(editor, {
      at: [...nodePath, nestedScriptSegmentIndex],
    });
    return true;
  }

  return false;
};

export default ensureNoNestedScriptSegments;