import colorString from 'color-string';

export const AllowedHighlightColors: string[] = [
  '#ff0000',    // Red
  '#ff9900',    // Pure Orange
  '#ffff00',    // Yellow
  '#00ff00',    // Green
  '#00ffff',    // Cyan
  '#0000ff',    // Blue
  '#9900FF',    // Purple
  '#ff00ff',    // Magenta
  '#ffffff',    // White
];

//
// See: https://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
//
// W3C Web Content Accessibility Guidelines (WCAG) 2.0 defines relative luminance as the relative
// brightness of any point in a colorspace, normalized to 0 for darkest black and 1 for lightest
// white.
//
// For the sRGB colorspace, the relative luminance of a color is defined as
//     L = 0.2126 * R + 0.7152 * G + 0.0722 * B where R, G and B are defined as:
//
// Where:
//     if RsRGB <= 0.03928 then R = RsRGB/12.92 else R = ((RsRGB+0.055)/1.055) ^ 2.4
//     if GsRGB <= 0.03928 then G = GsRGB/12.92 else G = ((GsRGB+0.055)/1.055) ^ 2.4
//     if BsRGB <= 0.03928 then B = BsRGB/12.92 else B = ((BsRGB+0.055)/1.055) ^ 2.4
//
// And RsRGB, GsRGB, and BsRGB are defined as:
//     RsRGB = R8bit/255
//     GsRGB = G8bit/255
//     BsRGB = B8bit/255
//
const RED = 0.2126;   // Taken from W3C Web Content Accessibility Guidelines (WCAG) 2.0 (SMPTE C, Rec. 709 color channel weights)
const GREEN = 0.7152; // Taken from W3C Web Content Accessibility Guidelines (WCAG) 2.0 (SMPTE C, Rec. 709 color channel weights)
const BLUE = 0.0722;  // Taken from W3C Web Content Accessibility Guidelines (WCAG) 2.0 (SMPTE C, Rec. 709 color channel weights)
const GAMMA = 2.4;    // Taken from W3C Web Content Accessibility Guidelines (WCAG) 2.0

export type RGBColor = [number, number, number];
export type RGBAColor = [number, number, number, number];
export type HSLColor = [number, number, number];
export type HSLAColor = [number, number, number, number];
export type LABColor = [number, number, number];

/**
 * Returns true if the red, green and blue color channels are equal - indicating this is some shade
 * of grey between black and white.
 * @param color Array of color channels for [r,g,b] or [r,g,b,a]
 * @returns true/false
 */
export function isGreyScale(color: RGBColor | RGBAColor): boolean {
  const [r, g, b] = color;

  return (
    r === g
      && g === b
      && r === b
  );
}

/**
 * Calculate a number representing the luminance or "brightness" of a color. This is used to to
 * calculate contrast between text and background.
 * See: https://stackoverflow.com/a/9733420
 * @param r
 * @param g
 * @param b
 * @returns
 */
export function luminance(r: number, g: number, b: number) {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928
      ? v / 12.92
      : Math.pow((v + 0.055) / 1.055, GAMMA);
  });
  return a[0] * RED + a[1] * GREEN + a[2] * BLUE;
}

/**
 * Calculate the contrast ratio between two colors.
 * @param rgb1
 * @param rgb2
 * @returns
 */
export function contrast(rgb1: RGBColor, rgb2: RGBColor) {
  const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
  const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
}

/**
 * See: https://css-tricks.com/converting-color-spaces-in-javascript/
 * @param color Array of color channels for [r,g,b] or [r,g,b,a]
 * @returns Array of color channels for [h,s,l,a]
 */
export function RGBToHSL(color: RGBColor | RGBAColor): HSLAColor {
  //
  // Convert color channels to be fractions of 1
  const r = color[0] / 255;
  const g = color[1] / 255;
  const b = color[2] / 255;
  const a = color.length === 4 ? color[3] : 1;

  // Find greatest and smallest channel values
  const cmin = Math.min(r,g,b),
    cmax = Math.max(r,g,b),
    delta = cmax - cmin;

  let h = 0,
    s = 0,
    l = 0;

  // Calculate hue
  // No difference
  if (delta == 0)
    h = 0;
  // Red is max
  else if (cmax == r)
    h = ((g - b) / delta) % 6;
  // Green is max
  else if (cmax == g)
    h = (b - r) / delta + 2;
  // Blue is max
  else
    h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  // Make negative hues positive behind 360°
  if (h < 0) {
    h += 360;
  }

  // Calculate lightness
  l = (cmax + cmin) / 2;

  // Calculate saturation
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  // Multiply l and s by 100
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return [h, s, l, a];
}

/**
 * See: https://css-tricks.com/converting-color-spaces-in-javascript/
 * @param rgb Array of color channels for [h,s,l] or [h,s,l,a]
 * @returns Array of color channels for [r,g,b,a]
 */
export function HSLToRGB(color: HSLColor | HSLAColor): RGBAColor {
  //
  // Convert color channels to be fractions of 1
  const h = color[0];
  const s = color[1] / 100;
  const l = color[2] / 100;
  const a = color.length === 4 ? color[3] : 1;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs((h / 60) % 2 - 1));
  const m = l - c/2;

  let r = 0;
  let g = 0;
  let b = 0;

  if (0 <= h && h < 60) {
    r = c; g = x; b = 0;
  } else if (60 <= h && h < 120) {
    r = x; g = c; b = 0;
  } else if (120 <= h && h < 180) {
    r = 0; g = c; b = x;
  } else if (180 <= h && h < 240) {
    r = 0; g = x; b = c;
  } else if (240 <= h && h < 300) {
    r = x; g = 0; b = c;
  } else if (300 <= h && h < 360) {
    r = c; g = 0; b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return [r, g, b, a] as RGBAColor;
}

/**
 * Convert an RGB color to LAB color space - used for detecting color similarity according to the
 * human eye. The perceived difference between colors when viewed by humans is not the same as the
 * mathemtical difference between red, green and blue components of the color. The LAB color space
 * more accurately reflects perception of similarity or difference by humans.
 *
 * Code is based off of the pseudocode found on www.easyrgb.com
 *
 * @param rgb Array of color channels for [r,g,b] or [r,g,b,a]
 * @returns Array []
 */
export function RGBToLAB(rgb: RGBColor | RGBAColor): LABColor {
  //
  // Convert color channels to be fractions of 1
  let r = rgb[0] / 255;
  let g = rgb[1] / 255;
  let b = rgb[2] / 255;
  const a = rgb.length === 4 ? rgb[3] : 1;

  r = (r > 0.04045) ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
  g = (g > 0.04045) ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
  b = (b > 0.04045) ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;

  let x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
  let y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
  let z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;

  x = (x > 0.008856) ? Math.pow(x, 1/3) : (7.787 * x) + 16/116;
  y = (y > 0.008856) ? Math.pow(y, 1/3) : (7.787 * y) + 16/116;
  z = (z > 0.008856) ? Math.pow(z, 1/3) : (7.787 * z) + 16/116;

  return [(116 * y) - 16, 500 * (x - y), 200 * (y - z)];
}

/**
 * Calculate the perceptual distance between colors in CIELAB
 * @param labA
 * @param labB
 * @returns
 */
export function deltaE(labA: LABColor, labB: LABColor): number {
  const deltaL = labA[0] - labB[0];
  const deltaA = labA[1] - labB[1];
  const deltaB = labA[2] - labB[2];
  const c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
  const c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
  const deltaC = c1 - c2;
  let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
  deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
  const sc = 1.0 + 0.045 * c1;
  const sh = 1.0 + 0.015 * c1;
  const deltaLKlsl = deltaL / (1.0);
  const deltaCkcsc = deltaC / (sc);
  const deltaHkhsh = deltaH / (sh);
  const i = deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
  return i < 0 ? 0 : Math.sqrt(i);
}

export function findClosestHighlightColor(rgbColor: RGBColor | RGBAColor): string | undefined {
  //
  // If we were provided an alpha-transparency value, it must be greater than 10% opacity to not be
  // considered "transparent". We will not consider "transparent" backgrounds as a highlight.
  //
  // If we were not provided an alpha channel value, it is implied to be 100% opacity.
  //
  const alpha = (rgbColor.length >= 4)
    ? (rgbColor[3] ?? 1)
    : 1;
  if(alpha < 0.1) {
    // The background color is visually "transparent" and we should ignore it.
    return undefined;
  }

  //
  // We will only allow white highlight as an exact match - not a near match.
  // We will not allow grey colors/shades in the near match color selection below.
  //
  if(rgbColor[0] === 255 && rgbColor[1] === 255 && rgbColor[2] === 255) {
    // Requested highlight color is exactly white. We will allow it.
    return '#ffffff';
  }

  try {
    //
    // Find the closest matching highlight color from our approved list of highly saturated
    // highlight colors for FluidPrompter.
    //
    const requestedCssColorAsLAB = RGBToLAB(rgbColor);

    //
    // Calculate the deltaE between the requested color and all approved highlight colors and find
    // the highlight color with the lowest deltaE.
    //
    const deltaEColorInfo = AllowedHighlightColors
      // parse CSS string representation of color
      .map((color) => colorString.get(color))
      // filter out any unparseable values that were returned as null
      .filter(color => color !== null && !isGreyScale(color.value))
      // calculate a deltaE for each approved highlight color compared to the requested color
      .map((color) => ({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        color: color!.value,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        deltaE: deltaE(RGBToLAB(color!.value), requestedCssColorAsLAB)
      }));

    // console.log(`deltaEColorInfo([${rgbColor[0]},${rgbColor[1]},${rgbColor[2]}])`, deltaEColorInfo);

    const lowestDeltaEColorInfo = deltaEColorInfo
      // We will consider a color to "match" if the deltaE score is below 30.
      .filter(colorInfo => colorInfo.deltaE < 30)
      // sort the list so the lowest deltaE value it first
      .sort((a, b) => a.deltaE - b.deltaE)
      // take the first item from the result set (lowest deltaE value)
      .find((entry) => entry.color);

    if(lowestDeltaEColorInfo) {
      return colorString.to.hex(lowestDeltaEColorInfo.color).toLowerCase();
    }
  } catch(err) {
    return undefined;
  }
}