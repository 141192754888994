import React from 'react';
import {
  PlayMessage,
  PauseMessage,
  EditMessage,
  SetScrollSpeedMessage,
  ResetMessage,
  NavigateMessage,
  TestMessage,
  SetReverseMessage,
  GenericMessage,
} from '@fluidprompter/core';
import TextAlign from '../../models/TextAlign';

import useConfigurationStore from '../../state/ConfigurationStore';
import usePrompterSession from '../../state/PrompterSessionState';
import useInputMiddleware,
{
  // InputMiddlewareCallback,
  // InputMiddlewareResult,
  KeyboardHandlersCollection,
  // KeyboardHandlers,
  InputMiddlewareProps,
  WheelHandlersCollection,
  ButtonHandlersCollection,
  AxisHandlersCollection
} from '../../hooks/useInputMiddleware';

import { useAppController } from '../../controllers/AppController';
import { DeviceAxisEvent } from '../../devices/events/DeviceAxisEvent';
import { DeviceWheelEvent } from '../../devices/events/DeviceWheelEvent';
import AnalogPosition from '../../models/AnalogPosition';

// export interface PrompterInputMiddlewareHook {
// }

const usePrompterInputMiddleware = () /*: PrompterInputMiddlewareHook*/ => {

  const appController = useAppController();

  // console.log(`1. usePrompterInputMiddleware executed`);

  //
  // Our keyboard handlers may be re-mapped as part of app configuration.
  //
  // const configStore = useConfigurationStore(state => ({
  //   scrollReversed: state.scrollReversed,
  //   setScrollSpeed: state.setScrollSpeed,
  // }), shallow);

  //
  // Cache our keymappings so they aren't recomputed on every re-render of the component.
  //
  const keyboardHandlers = React.useMemo<KeyboardHandlersCollection>(() => {
    // console.log(`2. usePrompterInputMiddleware keyboardHandlers`);
    //
    //
    //
    const keyHandlers: KeyboardHandlersCollection = [{
      hotkeys: 't',
      keydown: (e) => {
        if(!e.isContentEditableFocused) {
          appController.dispatchMessage(new TestMessage());
        }
      }
    }, {
      hotkeys: 'esc',
      keydown: (e) => {
        const localState = usePrompterSession.getState();
        if(localState.isPlaying) {
          appController.dispatchMessage(new PauseMessage());
          return;
        }

        if(localState.isPaused) {
          appController.dispatchMessage(new EditMessage());
          return;
        }

        if(e.isContentEditableFocused) {
          // Blur the ContentEditable - aka Stop Editing.
          const activeHTMLElement = window.document.activeElement as HTMLElement;
          activeHTMLElement.blur();
          return;
        }

        appController.dispatchMessage(new ResetMessage());
      }
    }, {
      hotkeys: 'f5',
      keydown: (e) => {
        e.preventDefault();
        if(!usePrompterSession.getState().isPlaying) {
          appController.dispatchMessage(new PlayMessage());
        }
      }
    }, {
      hotkeys: ['enter','space'],
      skipWhenEditableFocused: true,
      keydown: (e) => {
        if(e.isEditableFocused) {
          return;
        }

        e.preventDefault();
        appController.dispatchMessage(usePrompterSession.getState().isPlaying ? new PauseMessage() : new PlayMessage());
      }
    }, {
      hotkeys: 'mod+\\',
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage('prompter.editor.format.clear');
      }
    }, {
      hotkeys: 'ctrl+space',
      keydown: (e) => {
        // When editing, Ctrl+Space will clear selection formatting.
        if(e.isEditableFocused) {
          // Is this necessary? Or can we just preventDefault() within the SlateEditor to stop
          // keyboard keys from reaching our middleware?
          const windowSelection = window.getSelection();
          const hasSelection = windowSelection && windowSelection.type === 'Range';

          if(hasSelection) {
            appController.dispatchMessage('prompter.editor.format.clear');
            return;
          }
        }

        // When prompting, Ctrl+Space will switch back to edit mode.
        e.preventDefault();
        appController.dispatchMessage(usePrompterSession.getState().isEditing ? new PlayMessage() : new EditMessage());
      }
    }, {
      hotkeys: 'mod+enter',
      keydown: (e) => {
        e.preventDefault();

        //
        // When editing, Ctrl+Enter/Cmd+Enter will split the current prompter segment or insert a
        // new prompter segment.
        //
        if(e.isEditableFocused) {
          appController.dispatchMessage('prompter.editor.segment.split');
          return;
        }

        //
        // When prompting, Ctrl+Space will switch back to edit mode.
        //
        appController.dispatchMessage(usePrompterSession.getState().isEditing ? new PlayMessage() : new EditMessage());
      }
    }, {
      byKey: true,
      hotkeys: ['add', '='],
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        if(e.repeat) {
          return;
        }

        appController.dispatchMessage('prompter.state.momentaryplay.start');
      },
      keyup: (e) => {
        e.preventDefault();
        appController.dispatchMessage('prompter.state.momentaryplay.stop');
      }
    }, {
      byKey: true,
      hotkeys: '-',
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        if(e.repeat) {
          return;
        }

        appController.dispatchMessage('prompter.state.momentaryreverse.start');
      },
      keyup: (e) => {
        e.preventDefault();
        appController.dispatchMessage('prompter.state.momentaryreverse.stop');
      }
    }, {
      hotkeys: ['F2', 'mod+shift+enter'],
      keydown: (e) => {
        e.preventDefault();

        //
        // When prompting, Ctrl/Cmd+Shift+Enter will switch back to edit mode and place the cursor
        // at the nearest editable text node.
        //
        // If already editing, Ctrl/Cmd+Shift+Enter will play the promtper from the current
        // position.
        //
        if(!usePrompterSession.getState().isEditing) {
          appController.dispatchMessages([
            new EditMessage(),
            new GenericMessage('prompter.editor.focusatcue'),
          ]);
        } else {
          appController.dispatchMessage(new PlayMessage());
        }
      }
    }, {
      hotkeys: 'F7',
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage('prompter.background.snapshot.countdown');
      }
    }, {
      hotkeys: 'up',
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PageUp));
      }
    }, {
      hotkeys: 'down',
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PageDown));
      }
    }, {
      hotkeys: 'shift+up',
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        // Nudge: Navigate -1px
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Position, {
          scrollOffset: -1,
        }));
      }
    }, {
      hotkeys: 'shift+down',
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        // Nudge: Navigate +1px
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Position, {
          scrollOffset: 1,
        }));
      }
    }, {
      hotkeys: 'ctrl+shift+up',
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();

        // Nudge: Cue Position -1%
        const localConfig = useConfigurationStore.getState();
        localConfig.setCuePositionPercentage(localConfig.cuePositionPercentage - 1);
      }
    }, {
      hotkeys: 'ctrl+shift+down',
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();

        // Nudge: Cue Position +1%
        const localConfig = useConfigurationStore.getState();
        localConfig.setCuePositionPercentage(localConfig.cuePositionPercentage + 1);
      }
    }, {
      hotkeys: 'left',
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        let newSpeed = useConfigurationStore.getState().scrollSpeed;
        newSpeed -= 10;
        if(newSpeed < 0) { newSpeed = 0; }
        appController.dispatchMessage(new SetScrollSpeedMessage(newSpeed));
      }
    }, {
      hotkeys: 'right',
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        let newSpeed = useConfigurationStore.getState().scrollSpeed;
        newSpeed += 10;
        if(newSpeed > 500) { newSpeed = 500; }
        appController.dispatchMessage(new SetScrollSpeedMessage(newSpeed));
      }
    }, {
      hotkeys: 'home',
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Start));
      }
    }, {
      hotkeys: 'end',
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.End));
      }
    }, {
      hotkeys: 'pageup',
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PrevSegment));
      }
    }, {
      hotkeys: 'pagedown',
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.NextSegment));
      }
    }, {
      hotkeys: ['r'],
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        if(e.repeat) {
          // We don't need to spam with repeated setreverse messages.
          return;
        }
        appController.dispatchMessage(new SetReverseMessage(SetReverseMessage.Operation.Enable));
      },
      keyup: (e) => {
        e.preventDefault();
        appController.dispatchMessage(new SetReverseMessage(SetReverseMessage.Operation.Disable));
      }
    }, {
      hotkeys: ['alt+r'],	// mod+r = right align text
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage(new SetReverseMessage(SetReverseMessage.Operation.Toggle));
      }
    }, {
      hotkeys: ['.', 'b'],
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        if(e.repeat) {  // We don't want to allow key repeats.
          return;
        }
        appController.dispatchMessage('prompter.content.togglevisbility');
      }
    }, {
      hotkeys: ['m'],
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        if(e.repeat) {  // We don't want to allow key repeats.
          return;
        }
        appController.dispatchMessage('prompter.background.togglemute');
      }
    }, {
      hotkeys: ['f1','alt+h'],
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage('prompter.local.showhelp');
      }
    }, {
      hotkeys: ['alt+up','ctrl+shift+.'],	// ctrl+shift+> doesn't work
      keydown: (e) => {
        e.preventDefault();
        useConfigurationStore.getState().increaseTextSize();
      }
    }, {
      hotkeys: ['alt+down','ctrl+shift+,'],
      keydown: (e) => {
        e.preventDefault();
        useConfigurationStore.getState().decreaseTextSize();
      }
    }, {
      hotkeys: 'alt+left',
      keydown: (e) => {
        e.preventDefault();
        useConfigurationStore.getState().decreaseContentWidth();
      }
    }, {
      hotkeys: 'ctrl+alt+left',
      keydown: (e) => {
        e.preventDefault();
        useConfigurationStore.getState().shiftLeft();
      }
    }, {
      hotkeys: 'alt+right',
      keydown: (e) => {
        e.preventDefault();
        useConfigurationStore.getState().increaseContentWidth();
      }
    }, {
      hotkeys: 'ctrl+alt+right',
      keydown: (e) => {
        e.preventDefault();
        useConfigurationStore.getState().shiftRight();
      }
    }, {
      hotkeys: 'mod+l',
      keydown: (e) => {
        e.preventDefault();
        useConfigurationStore.getState().setContentJustification(TextAlign.Left);
      }
    }, {
      hotkeys: 'mod+e',
      keydown: (e) => {
        e.preventDefault();
        useConfigurationStore.getState().setContentJustification(TextAlign.Center);
      }
    }, {
      hotkeys: 'mod+r',
      keydown: (e) => {
        e.preventDefault();
        useConfigurationStore.getState().setContentJustification(TextAlign.Right);
      }
    }, {
      hotkeys: 'mod+j',
      keydown: (e) => {
        e.preventDefault();
        useConfigurationStore.getState().setContentJustification(TextAlign.Justify);
      }
    }, {
      hotkeys: 'mod+o',
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage('openfile');
      }
    }, {
      hotkeys: 'mod+s',
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage('savefile');
      }
    }, {
      hotkeys: '0',
      byKey: true,
      skipWhenEditableFocused: true,
      // keydown: (e) => {
      // 	e.preventDefault();
      // 	// IKAN Remote Play/Pause
      //  appController.dispatchMessage(usePrompterSession.getState().isPlaying ? new PauseMessage() : new PlayMessage());
      // }
      keydown: (e) => {
        e.preventDefault();
        if(e.repeat) {
          return;
        }
        appController.dispatchMessage('prompter.state.momentaryplay.start');
      },
      keyup: (e) => {
        e.preventDefault();
        appController.dispatchMessage('prompter.state.momentaryplay.stop');
      }
    }, {
      hotkeys: '1',
      byKey: true,
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        // IKAN Remote Left
        useConfigurationStore.getState().decreaseSpeed(10);
      }
    }, {
      hotkeys: '2',
      byKey: true,
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        // IKAN Remote Rewind
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PageUp));
      }
    }, {
      hotkeys: '3',
      byKey: true,
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        // IKAN Remote Right
        useConfigurationStore.getState().increaseSpeed(10);
      }
    }, {
      hotkeys: '4',
      byKey: true,
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        // IKAN Remote Fast Forward
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PageDown));
      }
    }, {
      hotkeys: '5',
      byKey: true,
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        // IKAN Remote Skip Back
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.PrevSegment));
      }
    }, {
      hotkeys: '6',
      byKey: true,
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        // IKAN Remote Skip Forward
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.NextSegment));
      }
    }, {
      hotkeys: '8',
      byKey: true,
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        // IKAN Remote mirror screen
        useConfigurationStore.getState().toggleFlipHorizontal();
      }
    }, {
      hotkeys: 'mod+1',
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Position, {
          contentNumber: 1
        }));
      }
    }, {
      hotkeys: 'mod+2',
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Position, {
          contentNumber: 2
        }));
      }
    }, {
      hotkeys: 'mod+3',
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Position, {
          contentNumber: 3
        }));
      }
    }, {
      hotkeys: 'mod+4',
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Position, {
          contentNumber: 4
        }));
      }
    }, {
      hotkeys: 'mod+5',
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Position, {
          contentNumber: 5
        }));
      }
    }, {
      hotkeys: 'mod+6',
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Position, {
          contentNumber: 6
        }));
      }
    }, {
      hotkeys: 'mod+7',
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Position, {
          contentNumber: 7
        }));
      }
    }, {
      hotkeys: 'mod+8',
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Position, {
          contentNumber: 8
        }));
      }
    }, {
      hotkeys: 'mod+9',
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage(new NavigateMessage(NavigateMessage.Target.Position, {
          contentNumber: 9
        }));
      }
    }, {
      hotkeys: '`',
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage('prompter.local.appmenu.toggle');
      }
    }, {
      hotkeys: '/',
      byKey: true,
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage('prompter.local.presetmenu.toggle');
      }
    }, {
      hotkeys: 'mod+a',
      skipWhenEditableFocused: true,
      keydown: (e) => {
        e.preventDefault();
        appController.dispatchMessage('prompter.editor.selectall');
      }
      /*
		}, {
			hotkeys: 'ctrl+',
			keydown: (e) => {
				e.preventDefault();
				//
			}
		}, {
			hotkeys: ['',''],
			keydown: (e) => {
				e.preventDefault();
				//bus.emit('');
			}
		}, {
			hotkeys: ['',''],
			keydown: (e) => {
				e.preventDefault();
				//bus.emit('');
			}
		*/
    }];

    return keyHandlers;
  }, []);

  const buttonHandlers = React.useMemo<ButtonHandlersCollection>(() => {
    const buttonHandlers: ButtonHandlersCollection = [{
      button: 'ok',
      buttondown: (e) => {
        if(e.proposedAction) {
          appController.dispatchMessage(e.proposedAction);
        }
      },
      buttonup: (e) => {
        if(e.proposedAction) {
          appController.dispatchMessage(e.proposedAction);
        }
      }
    }, {
      button: 'plus',
      buttondown: (e) => {
        e.preventDefault();

        // const configState = useConfigurationStore.getState();
        // configState.setFlipVertical(configState.flipVertical);
        useConfigurationStore.getState().toggleFlipVertical();
      },
    }, {
      button: 'minus',
      buttondown: (e) => {
        e.preventDefault();

        // const configState = useConfigurationStore.getState();
        // configState.setFlipVertical(configState.flipVertical);
        useConfigurationStore.getState().toggleFlipHorizontal();
      },
    }];

    return buttonHandlers;
  }, [appController]);

  //
  // Handle wheel style inputs like mousewheel or jogwheel inputs.
  //
  const wheelHandlers = React.useMemo<WheelHandlersCollection>(() => {
    const wheelHandlers: WheelHandlersCollection = [{
      scroll: (e) => {
        let deltaValue = e.deltaY;

        if(usePrompterSession.getState().isPlaying) {
          switch(e.deltaMode) {
            default:
            case DeviceWheelEvent.DELTA_PIXEL:
              break;
            case DeviceWheelEvent.DELTA_LINE:
              deltaValue = deltaValue * 10;
              break;
            case DeviceWheelEvent.DELTA_PAGE:
              deltaValue = deltaValue * 100;
              break;
          }

          let newSpeed = useConfigurationStore.getState().scrollSpeed;
          newSpeed += deltaValue;	// e.deltaY;
          if(newSpeed < 0) { newSpeed = 0; }
          if(newSpeed > 500) { newSpeed = 500; }
          appController.dispatchMessage(new SetScrollSpeedMessage(newSpeed));
        } else {
          switch(e.deltaMode) {
            default:
            case DeviceWheelEvent.DELTA_PIXEL:
              break;
            case DeviceWheelEvent.DELTA_LINE:
              deltaValue = deltaValue * 30;
              break;
            case DeviceWheelEvent.DELTA_PAGE:
              deltaValue = deltaValue * 600;
              break;
          }

          appController.dispatchMessage('prompter.scrollby', {
            deltaY: deltaValue	// e.deltaY
          });
        }
      }
    }];

    return wheelHandlers;
  }, []);

  const axisHandlers = React.useMemo<AxisHandlersCollection>(() => {
    const axisHandlersInternal: AxisHandlersCollection = [{
      change: (e: DeviceAxisEvent) => {
        // console.log('usePrompterInputMiddleware.AxisHandler');

        //
        // BEGIN TEMP mod
        //
        // This was prepared for recording some app demos for the website.
        //
        // console.log(`usePrompterInputMiddleware.AxisHandler for Axis #${e.axisNumber}`);
        // switch(e.axisNumber) {
        //   case 0: {
        //     // configStore.setTextSize(newValue as number) = 10pt - 52pt
        //     const proposedTextSize = Math.round(Math.abs(e.value * 32) / 32767) + 20;
        //     // const scrollInReverse = (e.analogPosition === AnalogPosition.Negative || e.analogPosition === AnalogPosition.Minimum);
        //     useConfigurationStore.getState().setTextSize(proposedTextSize); // = 10pt - 100pt
        //     break;
        //   }
        //   case 1: {
        //     const proposedContentWidth = Math.round(Math.abs(e.value * 62) / 32767) + 38;
        //     useConfigurationStore.getState().setContentWidth((100 - proposedContentWidth) + 38); //  = 30% - 100%
        //     break;
        //   }
        //   // default:
        //   //   throw new Error('Unexpected axis number for AirTurn remote.');
        // }
        // END TEMP mod
        //

        //
        // If our analog position changes from neutral or unknown to a non-neutral value, the user
        // wants to scroll and we should be playing.
        //
        // ex: move analog pedal away from minimum position to increase scroll speed
        // ex: begin turning shuttle wheel on contour shuttle controller away from center position
        //
        if(e.analogPositionChanged
					&& (e.analogPositionPrevious === AnalogPosition.Neutral
						|| e.analogPositionPrevious === AnalogPosition.Unknown)
					&& e.analogPosition !== AnalogPosition.Neutral
        ) {
          // console.log(`e.analogPositionPrevious=${e.analogPositionPrevious} and e.analogPosition=${e.analogPosition}`);
          appController.dispatchMessage(new PlayMessage());
        }

        // Scale our analogue value range of 0-32767 to be 50-350 as a reasonable range for
        // teleprompter scrolling speed. In the future this minimum and maximum could be
        // user preferences.
        const newSpeed = Math.round(Math.abs(e.value * 300) / 32767) + 50;
        let scrollInReverse = (e.analogPosition === AnalogPosition.Negative || e.analogPosition === AnalogPosition.Minimum);

        //
        // If our analog position changes from non-neutral value back to neutral position, then the
        // user wishes to stop scrolling and we should pause and also clear any reverse scrolling
        // state if set.
        //
        // ex: return analog pedal to minimum position
        // ex: release shuttle wheel on contour shuttle controller
        //
        if(e.analogPositionChanged
					&& e.analogPositionPrevious !== AnalogPosition.Neutral
					&& e.analogPosition === AnalogPosition.Neutral
        ) {
          scrollInReverse = false;
          appController.dispatchMessage(new PauseMessage());
        }

        appController.dispatchMessage(new SetScrollSpeedMessage(newSpeed, scrollInReverse));
      },
    }];

    return axisHandlersInternal;
  }, []);

  const inputMiddlewareProps = React.useMemo<InputMiddlewareProps>(() => {
    // console.log('3. usePrompterInputMiddleware inputMiddlewareProps');
    const results: InputMiddlewareProps = {
      keyboard: keyboardHandlers,
      buttons: buttonHandlers,
      wheels: wheelHandlers,
      axes: axisHandlers,
    };

    return results;
  }, [keyboardHandlers, buttonHandlers, wheelHandlers, axisHandlers]);

  //
  // Register our root middleware functions and receive methods for dispatching input events.
  //
  // This is our root set of keyboard event handlers. If various pop-up dialogs also
  // registered keyboard handling middleware, they may intercept some or all keyboard events.
  //
  const {
    dispatchKeyboardEvent
  } = useInputMiddleware(inputMiddlewareProps);

  // Bubble vs Capture Events: https://javascript.info/bubbling-and-capturing
  React.useEffect(() => {
    // document should always be defined, except in unit testing or mock environments.
    if (typeof document === 'undefined') {
      return;
    }

    // console.log('usePrompterInputMiddleware register key listeners');

    document.addEventListener('keydown', dispatchKeyboardEvent, false);
    document.addEventListener('keyup', dispatchKeyboardEvent, false);

    return () => {
      // cleanup function
      // console.log('usePrompterInputMiddleware UNregister key listeners');
      document.removeEventListener('keydown', dispatchKeyboardEvent, false);
      document.removeEventListener('keyup', dispatchKeyboardEvent, false);
    };
  }, [dispatchKeyboardEvent]);

  return {
  };
};

export default usePrompterInputMiddleware;