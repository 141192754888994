import { Transforms, Path, Node } from 'slate';
import { ElementTypes, PrompterEditor, PrompterElement } from '../../../models/EditorTypes';

/**
 * Make sure a SegmentElement always contains at least 1 paragraph node child.
 * @param editor
 * @param node
 * @param nodePath
 * @returns
 */
const ensureScriptSegmentHasChild = (editor: PrompterEditor, node: Node, nodePath: Path): boolean => {
  const nodePrompterElement = node as PrompterElement;
  if(nodePrompterElement.type !== ElementTypes.SCRIPT_SEGMENT) {
    return false;
  }

  nodePrompterElement.children.forEach((rootChild, index) => {
    const rootChildPath = [...nodePath, index];
    if(rootChild.type !== ElementTypes.PARAGRAPH) {
      // A child of SCRIPT_SEGMENT is not a paragraph. Wrap this node with a paragraph.
      Transforms.wrapNodes(editor, {
        type: ElementTypes.PARAGRAPH,
        children: [],
      }, {
        at: rootChildPath
      });
      return true;
    }
  });
  return false;
};

export default ensureScriptSegmentHasChild;