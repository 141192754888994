import React, { useCallback, useEffect } from 'react';

import { useMessageHandler } from '../../../controllers/AppController';
import usePrompterSession from '../../../state/PrompterSessionState';
import { IVirtualCursor } from '../../../state/PrompterSessionState/ConnectedDevicesSlice';

/**
 * The CursorLocator component will be placed inline at the remote editors cursor position in the
 * script. This component should take up 0 width, and is only used to measure the position where
 * the remote editor's cursor should be rendered.
 *
 * We will then position the `VirtualCursor` component using the CursorLocator's position. However
 * the `VirtualCursor` component will be positioned absolute from outside the script DOM.
 */
function CursorLocator() {

  const cursorLocatorRef = React.useRef<HTMLSpanElement | null>(null);

  //
  // Measure the size and position of the CursorLocator element so that we can place the virtual
  // cursor with position absolute, outside of the script content, so we don't mess up the flow.
  //
  const measureCursorLocator = useCallback(() => {
    const cursorLocatorEl = cursorLocatorRef.current;
    if(!cursorLocatorEl) {
      return;
    }

    const cursorRect = cursorLocatorEl.getBoundingClientRect();
    const remoteCursor: IVirtualCursor = {
      top: cursorRect.top + document.documentElement.scrollTop,
      left: cursorRect.left,
      height: cursorRect.height,
    };
    usePrompterSession.getState().setVirtualCursors([remoteCursor]);
  }, [cursorLocatorRef]);
  useMessageHandler('prompter.contentresized', () => {
    measureCursorLocator();
  });

  //
  // Use a callback ref to intercept ref assignment so that we know when PrompterContent is
  // re-rendered in the DOM.
  //
  // If we just adjusted prompter content appearance (content width, text size, left gutter, line
  // height) then we may want to restore the script position that was cached before making the
  // configuration change.
  //
  const cursorCallbackRef: React.RefCallback<HTMLSpanElement> = useCallback((newRef: HTMLSpanElement) => {
    if(!newRef) {
      return;
    }
    cursorLocatorRef.current = newRef;

    measureCursorLocator();
  }, [cursorLocatorRef, measureCursorLocator]);

  //
  // When our editorSelection changes, we need to re-measure the cursor location.
  //
  const editorSelection = usePrompterSession(state => state.editorSelection);
  useEffect(() => {
    measureCursorLocator();
  }, [editorSelection, measureCursorLocator]);

  return (<span
    className='CursorLocator'
    ref={cursorCallbackRef}
  ></span>);
}

export default CursorLocator;