import { Transforms, Path, Node, Editor } from 'slate';
import { ElementTypes, PrompterEditor, PrompterElement } from '../../../models/EditorTypes';

/**
 * Make sure a SegmentElement always contains at least 1 paragraph node child.
 * @param editor
 * @param node
 * @param nodePath
 * @returns
 */
const ensureScriptHasStartAndEndElements = (editor: PrompterEditor, node: Node, nodePath: Path): boolean => {
  if(!Editor.isEditor(node)) {
    return false;
  }

  let madeChanges = false;

  //
  // Make sure our script always starts with a `ElementTypes.STARTSCRIPT` node!
  //
  const firstNode = node.children[0] as PrompterElement;
  if(firstNode.type !== ElementTypes.STARTSCRIPT) {
    // The first node in our editor is NOT a start of script element.
    // Let's insert a startscript node now!
    Transforms.insertNodes<PrompterElement>(editor, {
      type: ElementTypes.STARTSCRIPT,
      children: [{ text: '' }]
    } as Node, {
      at: [0]
    });
    madeChanges = true;
  }

  const childrenLength = node.children.length;
  const lastNode = node.children[childrenLength - 1] as PrompterElement;
  if(lastNode.type !== ElementTypes.ENDSCRIPT) {
    // The first node in our editor is NOT a start of script element.
    // Let's insert a startscript node now!
    Transforms.insertNodes(editor, {
      type: ElementTypes.ENDSCRIPT,
      children: [{ text: '' }]
    } as Node, {
      at: [childrenLength]
    });
    madeChanges = true;
  }

  return madeChanges;
};

export default ensureScriptHasStartAndEndElements;