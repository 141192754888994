import React, { useEffect, useRef } from 'react';
import { Transforms, Range } from 'slate';
import { useFocused, useSlateStatic } from 'slate-react';
import { useAppController } from '../../../controllers/AppController';
import { EditorActiveMessage, SyncMessage } from '@fluidprompter/core';
import usePrompterSession from '../../../state/PrompterSessionState';

/**
 * Detects change in the focus state of the slate editor, using the useFocused() hook.
 *
 * Will notify registered callback of changes in the slate focus state so that it can be propogated
 * outside of the Slate component context.
 * @param props
 * @returns
 */
const SlateFocusMonitor = () => {
  const appController = useAppController();

  const editor = useSlateStatic();
  const focusedRef = useRef<boolean>();
  const focused = useFocused();

  useEffect(() => {
    if(focusedRef.current === focused) {
      return;
    }
    focusedRef.current = focused;

    usePrompterSession.getState().setEditorFocused(focused);

    //
    // The local prompter just focused the editor.
    //
    if(focused) {
      appController.dispatchMessage(new EditorActiveMessage());
    }

    //
    // The local prompter just unfocused (blurred) the editor.
    //
    if(!focused) {
      // We just unfocused the editor - clear any current editor selection.
      window.getSelection()?.removeAllRanges();

      if(editor.selection && Range.isExpanded(editor.selection)) {
        // Collapse the current selection
        Transforms.select(editor, editor.selection.focus);
      }

      // Send a passive sync message to connected peers which will include the current editor
      // focused state. This allows connected peers to hide the virtual cursor/selection when the
      // leader releases focus from the editor.
      appController.dispatchMessage(new SyncMessage());
    }
  }, [focused]);

  return (<></>);
};

export default SlateFocusMonitor;