import { Transforms, Path, Node, Editor } from 'slate';
import { ElementTypes, PrompterEditor, PrompterSegment } from '../../../models/EditorTypes';

/**
 * Make sure a SegmentElement always contains at least 1 paragraph node child.
 * @param editor
 * @param node
 * @param nodePath
 * @returns
 */
const numberScriptSegments = (editor: PrompterEditor, node: Node, nodePath: Path): boolean => {
  if(!Editor.isEditor(node)) {
    return false;
  }

  let madeChanges = false;

  //
  // Re-number our first 1-9 'ElementTypes.SCRIPT_SEGMENT' nodes as the user can jump to those
  // segments with shortcut-keys.
  //
  // Loop over all root children and number them, skipping any root children that are not of type 'paragraph'
  //
  let segmentCounter = 0;
  node.children.forEach((rootChild, index) => {
    const rootChildPath = [...nodePath, index];

    if(rootChild.type !== ElementTypes.SCRIPT_SEGMENT) {
      return;
    }

    segmentCounter++;

    const segmentNumber = (segmentCounter < 10 ? segmentCounter : undefined);

    if(rootChild.number !== segmentNumber) {
      // Assign the segmentNumber to the first 9 segments found in the editor root - otherwise
      // assign -1 to our node.
      Transforms.setNodes<PrompterSegment>(editor, { number: segmentNumber }, { at: rootChildPath });
      madeChanges = true;
    }
  });

  return madeChanges;
};

export default numberScriptSegments;