import React from 'react';
import { RenderLeafProps } from 'slate-react';
import CursorLocator from './CursorLocator';

import { get as parseCssColor } from 'color-string';
import { luminance } from '../../../utils/ColorUtils';

function TextElement(props: RenderLeafProps) {
  const { attributes, leaf } = props;
  let { children } = props;

  if (leaf.bold) {
    children = <strong {...attributes}>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em {...attributes}>{children}</em>;
  }

  if (leaf.underline) {
    children = <u {...attributes}>{children}</u>;
  }

  if (leaf.strike) {
    children = <del {...attributes}>{children}</del>;
  }

  if (leaf.highlight) {
    const highlightColor = leaf.highlight as string;

    //
    // Automatically choose white or black text based on the luminance of the highlight color.
    //
    let textColor: string | undefined = undefined;
    const colorInfo = parseCssColor(highlightColor);
    if(colorInfo && colorInfo.model === 'rgb') {
      const [r, g, b, a] = colorInfo.value;
      const l = luminance(r, g, b);

      if(l < 0.5) {
        textColor = '#ffffff';
      }
    }

    children = <mark {...attributes} style={{
      backgroundColor: highlightColor,
      color: textColor,
    }}>{children}</mark>;
  }

  if (leaf.code) {
    children = <code {...attributes}>{children}</code>;
  }

  if(leaf.masked) {
    // This content should be masked
    children = <del {...attributes}>{children}</del>;
  }

  if (leaf.isSelection) {
    return (
      <mark className={'EditorSelection'} {...attributes}>
        {leaf.isSelectionBackwards && leaf.isSelectionFocus && <CursorLocator />}
        {children}
        {!leaf.isSelectionBackwards && leaf.isSelectionFocus && <CursorLocator />}
      </mark>
    );
  }

  return (
    <span {...attributes}>
      {leaf.isCursor && <CursorLocator />}
      {children}
    </span>
  );
}

export default TextElement;