import React from 'react';
import {
  Editor,
  Node,
  BaseSelection,
  NodeEntry,
  Text,
} from 'slate';
import { PrompterEditor, PrompterText, PrompterTextMarks } from '../../../models/EditorTypes';
import { FormatCommands } from '../useEditorFormatCommandHandlers';

import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import HoveringToolbarToggleButtonGroup from './HoveringToolbarToggleButtonGroup';
import HoveringToolbarToggleButton from './HoveringToolbarToggleButton';
import HoveringToolbarButtonGroup from './HoveringToolbarButtonGroup';
import HoveringToolbarButton from './HoveringToolbarButton';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import HighlightIcon from '@mui/icons-material/Highlight';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import FormatColorTextIcon from '@mui/icons-material/FormatColorText';  // For Text color
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';  // For background color or fill color

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';  // This will be a block level element rather than a text style.
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';  // This will be a block level element rather than a text style.
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';  // This will be a block level element rather than a text style.

import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { CirclePicker, ColorResult } from 'react-color';
import { AllowedHighlightColors } from '../../../utils/ColorUtils';
import ClearIcon from '@mui/icons-material/Clear';

import usePrompterSession from '../../../state/PrompterSessionState';
import { isMacOs, isIOS } from 'react-device-detect';

const CtrlOrCmdString = (isMacOs || isIOS) ? 'Cmd' : 'Ctrl';


interface SelectionExpandedToolsProps {
  editor: PrompterEditor;
  commands: FormatCommands;
}

const SelectionExpandedTools = (props: SelectionExpandedToolsProps) => {
  const { editor, commands } = props;

  const { selectionActiveMarks } = usePrompterSession(state => state.editorSelectionState);

  const [highlightColorAnchorEl, setHighlightColorAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const highlightOpen = Boolean(highlightColorAnchorEl !== null);
  const handleHighlightColorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setHighlightColorAnchorEl(event.currentTarget);
  };
  const handleHighlightColorClose = () => {
    setHighlightColorAnchorEl(null);
  };
  const onHighlightColor = function(color: ColorResult) {
    commands.setHighlight(color.hex);
    setHighlightColorAnchorEl(null);
    // console.log(`ColorResult(${color.hex})`, color);
  };
  const onHighlightClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    commands.clearHighlight();
    setHighlightColorAnchorEl(null);
  };

  return (<>
    <HoveringToolbarToggleButtonGroup
      size="small"
      aria-label="Text Formatting"
    >
      <Tooltip
        title={`Toggle Bold (${CtrlOrCmdString}+B)`}
        arrow
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -6],
              },
            },
          ],
        }}
      >
        <HoveringToolbarToggleButton
          value="bold"
          selected={Boolean(selectionActiveMarks.bold)}
          onMouseDown={(e) => {
            commands.toggleBold();
            e.preventDefault();
          }}
          onFocus={(e) => { e.target.blur(); }}
        >
          <FormatBoldIcon/>
        </HoveringToolbarToggleButton>
      </Tooltip>
      <Tooltip
        title={`Toggle Italic (${CtrlOrCmdString}+I)`}
        arrow
      >
        <HoveringToolbarToggleButton
          value="italic"
          selected={Boolean(selectionActiveMarks.italic)}
          onMouseDown={(e) => {
            commands.toggleItalic();
            e.preventDefault();
          }}
          onFocus={(e) => { e.target.blur(); }}
        >
          <FormatItalicIcon/>
        </HoveringToolbarToggleButton>
      </Tooltip>
      <Tooltip
        title={`Toggle Underline (${CtrlOrCmdString}+U)`}
        arrow
      >
        <HoveringToolbarToggleButton
          value="underline"
          selected={Boolean(selectionActiveMarks.underline)}
          onMouseDown={(e) => {
            commands.toggleUnderline();
            e.preventDefault();
          }}
          onFocus={(e) => { e.target.blur(); }}
        >
          <FormatUnderlinedIcon/>
        </HoveringToolbarToggleButton>
      </Tooltip>
      <Tooltip
        title={`Strike Text (${isMacOs || isIOS ? '⌘+Shift+x' : 'Alt+Shift+5'})`}
        arrow
      >
        <HoveringToolbarToggleButton
          value="strike"
          selected={Boolean(selectionActiveMarks.strike)}
          onMouseDown={(e) => {
            commands.toggleStrikethrough();
            e.preventDefault();
          }}
          onFocus={(e) => { e.target.blur(); }}
        >
          <StrikethroughSIcon/>
        </HoveringToolbarToggleButton>
      </Tooltip>
    </HoveringToolbarToggleButtonGroup>
    <HoveringToolbarButtonGroup>
      <Tooltip
        title={`Highlight Text (${CtrlOrCmdString}+Shift+H)`}
        arrow
      >
        <HoveringToolbarToggleButton
          value="highlight"
          selected={Boolean(selectionActiveMarks.highlight)}
          onMouseDown={(e) => {
            commands.toggleHighlight();
            e.preventDefault();
          }}
          onFocus={(e) => { e.target.blur(); }}
        >
          <HighlightIcon/>
        </HoveringToolbarToggleButton>
      </Tooltip>
      <HoveringToolbarButton
        aria-controls={highlightOpen ? 'highlight-color-menu' : undefined}
        aria-expanded={highlightOpen ? 'true' : undefined}
        aria-label="Select Highlight Color"
        aria-haspopup="menu"
        onClick={handleHighlightColorClick}
        onFocus={(e) => { e.target.blur(); }}
        sx={{
          // minWidth: 'unset',
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <ArrowDropDownIcon />
      </HoveringToolbarButton>
    </HoveringToolbarButtonGroup>
    <Popover
      id={'highlight-color-menu'}
      open={highlightOpen}
      disableAutoFocus={true}
      anchorEl={highlightColorAnchorEl}
      onClose={handleHighlightColorClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: {
          color: '#eee',
          padding: 2,
          backgroundColor: '#131313',
        },
      }}
    >
      <Typography sx={{ pb: 2 }}>Choose Highlight Color</Typography>
      <CirclePicker
        width='176px'       // default 252px
        circleSize={28}     // default 28
        circleSpacing={16}  // default 14
        onChange={onHighlightColor}
        colors={AllowedHighlightColors}
      />
      <Stack
        direction="row"
        justifyContent="center"
        sx={{
          mt: 2,
        }}
      >
        <Button
          variant="outlined"
          startIcon={<ClearIcon/>}
          onClick={onHighlightClear}
        >Clear</Button>
      </Stack>
    </Popover>
    <Divider orientation="vertical" variant="middle" flexItem sx={{ borderColor: '#1E1E1E', borderWidth: '1.5px' }} />
    <HoveringToolbarToggleButtonGroup>
      <Tooltip
        title={`Clear Formatting (${isMacOs || isIOS ? 'Cmd+\\' : 'Ctrl+Space'})`}
        arrow
      >
        <HoveringToolbarToggleButton
          value="clearformatting"
          onMouseDown={(e) => {
            commands.clearFormatting();
            e.preventDefault();
          }}
          onFocus={(e) => { e.target.blur(); }}
        >
          <FormatClearIcon/>
        </HoveringToolbarToggleButton>
      </Tooltip>
    </HoveringToolbarToggleButtonGroup>
  </>);
};

export default SelectionExpandedTools;