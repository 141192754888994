import ButtonGroup, { buttonGroupClasses } from '@mui/material/ButtonGroup';
import { styled } from '@mui/material/styles';

const HoveringToolbarButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  [`& .${buttonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    minWidth: 'unset',  // Default is `min-width: 40px;`
    '&:hover': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:not(:last-of-type)': {
      borderRadius: theme.shape.borderRadius,
    }
  },
}));

export default HoveringToolbarButtonGroup;